import React, { useEffect, useState, useImperativeHandle, forwardRef, Ref, useMemo } from 'react';
import styled, { keyframes } from 'styled-components';
import { useTable, useSortBy, useGlobalFilter, useRowSelect, Column } from 'react-table';
import eventEmitter from '../../utils/EventEmitter';
import { RunnerResultType } from '../Windows/types'; // Ensure correct path to RunnerResultType
import {
  BankrollContainer,
  ActionButton,
  BankrollHeader,
  BetAmountCell,
  BetStringTextarea,
  ChartLabel,
  ChartsContainer,
  ChartValue,
  GlobalFilterInput,
  MeetingRaceCell,
  OddsCell,
  OverlayCell,
  ResultCell,
  RunnerNameCell,
  StyledTable,
  TableContainer,
  ChartSection,
  ChartTitle,
  ResetButton
} from '../../globalStyles';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

// Register the necessary components
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);



// ------------------------------ Bankroll Component Types (unchanged) ------------------------------
export interface BankrollInitPayload {
  initialBankroll: number;
  minBet?: number;
  targetProfit?: number;
}

export interface BankrollRef {
  initializeBankroll: (payload: BankrollInitPayload) => void;
  getBankroll: () => number;
  resetBankroll: (newBankroll: number) => void;
}

// ------------------------------ Bankroll Component (Updated Table Columns) ------------------------------
const Bankroll2 = forwardRef<BankrollRef, {}>(({ }, ref) => {
  // Initial bankroll amount
  const initialBankroll = 50; // Set your desired starting amount

  // Bankroll state
  const [maxBankroll, setMaxBankroll] = useState<number>(() => {
    const savedBankroll = localStorage.getItem('bankroll');
    return savedBankroll ? parseFloat(savedBankroll) : initialBankroll;
  });

  const [minBankroll, setMinBankroll] = useState<number>(maxBankroll);

  // Save bankroll to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('bankroll', maxBankroll.toString());
  }, [maxBankroll]);

  // Bankroll Strategy Parameters
  const [betStakePercentage, setBetStakePercentage] = useState<number>(6); // Start with 6%
  const [profitStakeIncreasePercentage, setProfitStakeIncreasePercentage] = useState<number>(7); // Increase to 7%
  const [profitThresholdForStakeIncrease, setProfitThresholdForStakeIncrease] = useState<number>(10); // 10% profit threshold
  const [reservePercentage, setReservePercentage] = useState<number>(3); // 3% to reserves
  const [reinvestPercentage, setReinvestPercentage] = useState<number>(5); // 5% reinvest
  const [stopLossPercentage, setStopLossPercentage] = useState<number>(30); // Example stop loss - 30%
  const [stopLossBufferPercentage, setStopLossBufferPercentage] = useState<number>(5); // Example buffer - 5%
  const [reserves, setReserves] = useState<number>(0);
  const [eachWaySimulation, setEachWaySimulation] = useState<boolean>(false); // Each Way Simulation toggle

  // Performance Metrics State
  const [roi, setRoi] = useState<number>(0);
  const [totalBets, setTotalBets] = useState<number>(0);
  const [betsWon, setBetsWon] = useState<number>(0);
  const [betsLost, setBetsLost] = useState<number>(0);
  const [winPercentage, setWinPercentage] = useState<number>(0);
  const [netProfit, setNetProfit] = useState<number>(0);


  // Runner data and row selection state (unchanged)
  const [data, setData] = useState<RunnerResultType[]>([]);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [globalFilter, setGlobalFilter] = useState<string>('');

  // Chart Data State - including ROI and Win %
  const [winCount, setWinCount] = useState<number>(0);
  const [lossCount, setLossCount] = useState<number>(0);
  const [avgOdds, setAvgOdds] = useState<number>(0);

  // New state variables for tracking races and meeting ROIs
  const [totalRacesSkipped, setTotalRacesSkipped] = useState<number>(0);
  const [totalRacesBettedOn, setTotalRacesBettedOn] = useState<number>(0);
  const [meetingROIs, setMeetingROIs] = useState<{ [key: string]: number }>({});

  // New state for tracking consecutive losses and configurable loss limit
  const [consecutiveLosses, setConsecutiveLosses] = useState<number>(0);
  const [lossLimit, setLossLimit] = useState<number>(6); // Configurable loss limit

  // New state to hold the generated bet strings
  const [betStrings, setBetStrings] = useState<string>('');

  // Add a new state to track the type of bet
  const [betType, setBetType] = useState<string>('WIN'); // Default to 'WIN'

  // Recalculate chart data and metrics whenever 'data' or 'bankroll' changes
  useEffect(() => {
    if (data && data.length > 0) {
      let wins = 0;
      let losses = 0;
      let totalOdds = 0;

      data.forEach(runner => {
        if (runner.result === 1) {
          wins++;
        } else {
          losses++;
        }
        totalOdds += runner.currentOdds;
      });

      setWinCount(wins);
      setLossCount(losses);
      setAvgOdds(totalOdds / data.length || 0); // Avoid division by zero
      setWinPercentage((wins / data.length) * 100 || 0);
    } else {
      setWinCount(0);
      setLossCount(0);
      setAvgOdds(0);
      setWinPercentage(0);
    }

    // Correct ROI calculation
    setRoi(((maxBankroll - initialBankroll) / initialBankroll) * 100 || 0);

  }, [data, maxBankroll, initialBankroll]);


  // Imperative methods exposed via ref (unchanged - except for resetting metrics)
  useImperativeHandle(ref, () => ({
    initializeBankroll(payload: BankrollInitPayload) {
      if (!payload.initialBankroll || payload.initialBankroll <= 0) {
        return;
      }
      setMaxBankroll(payload.initialBankroll);
      setMinBankroll(payload.initialBankroll); // Initialize minBankroll
      resetMetrics(); // Reset metrics when bankroll is initialized
    },
    getBankroll() {
      return maxBankroll;
    },
    resetBankroll(newBankroll: number) {
      if (newBankroll <= 0) {
        return;
      }
      setMaxBankroll(newBankroll);
      setMinBankroll(newBankroll); // Reset minBankroll
      resetMetrics(); // Reset metrics when bankroll is reset
    },
  }));

  const resetMetrics = () => {
    setRoi(0);
    setTotalBets(0);
    setBetsWon(0);
    setBetsLost(0);
    setWinPercentage(0);
    setNetProfit(0);
    setReserves(0);
    setData([]); // Clear data table as well on reset if needed
    setTotalRacesSkipped(0);
    setTotalRacesBettedOn(0);
    setMeetingROIs({});
    setConsecutiveLosses(0);
    setBetStrings('');
  };


  // Listen for runner data via eventEmitter (modified to simulate bet)
  useEffect(() => {
    eventEmitter.on('PLACE_BET', handleDataProcessed);
    eventEmitter.on('START_PROCESSING', handleStartProcessing);
    eventEmitter.on('STOP_PROCESSING', handleStopProcessing);
    return () => {
      eventEmitter.off('PLACE_BET', handleDataProcessed);
      eventEmitter.off('START_PROCESSING', handleStartProcessing);
      eventEmitter.off('STOP_PROCESSING', handleStopProcessing);
    };
  }, [maxBankroll, betStakePercentage, profitStakeIncreasePercentage, profitThresholdForStakeIncrease, reservePercentage, reinvestPercentage, stopLossPercentage, stopLossBufferPercentage, eachWaySimulation]); // Re-run effect if strategy parameters change

  const handleStartProcessing = () => {
    console.log('Start processing event received');
    resetMetrics();
    setMaxBankroll(initialBankroll);
    setMinBankroll(initialBankroll);
  };

  const handleStopProcessing = () => {
    console.log('Stop processing event received');
    resetMetrics();
    setMaxBankroll(initialBankroll);
    setMinBankroll(initialBankroll);
  };

  // Function to update race metrics
  const updateRaceMetrics = (runnerResult: RunnerResultType) => {
    if (runnerResult.result === 0) {
      setTotalRacesSkipped(prev => prev + 1);
    } else {
      setTotalRacesBettedOn(prev => prev + 1);
    }

    // Calculate Kelly Criterion
    const odds = runnerResult.currentOdds - 1;
    const probabilityWin = runnerResult.probabilityScore;
    const probabilityLoss = 1 - probabilityWin;
    const kellyFraction = (odds * probabilityWin - probabilityLoss) / odds;

    // Calculate Expected Value (EV)
    const ev = (probabilityWin * odds) - probabilityLoss;

    // Update meeting ROI using Kelly Criterion and EV
    const meetingName = runnerResult.meetingName;
    const currentROI = meetingROIs[meetingName] || 0;
    const newROI = currentROI + (kellyFraction * ev * maxBankroll);
    setMeetingROIs(prev => ({ ...prev, [meetingName]: newROI }));
  };

  // Utility function to format amounts to the required format
  const formatAmount = (amount: number): string => {
    return amount.toFixed(1).padStart(7, '0'); // Ensures the format 00000.0
  };

  // Update handleDataProcessed to calculate bet amounts using Kelly Criterion
  const handleDataProcessed = (newData: any, context: any) => {
    const runnerResult = context as RunnerResultType;
    if (!runnerResult) return;

    // Increment total bets
    setTotalBets(prev => prev + 1);

    // Calculate Kelly Fraction
    const odds = runnerResult.currentOdds - 1;
    const probabilityWin = runnerResult.probabilityScore;
    const probabilityLoss = 1 - probabilityWin;
    const kellyFraction = (odds * probabilityWin - probabilityLoss) / odds;

    // Calculate current bet stake based on Kelly Criterion
    let currentBetStake = maxBankroll * kellyFraction;
    // Convert to absolute value to ensure positive bet stake
    currentBetStake = Math.abs(currentBetStake);
    // Round to the nearest 50 cents
    currentBetStake = Math.round(currentBetStake * 2) / 2;

    let betProfit = 0;
    let betResultType = 0;

    const isWin = runnerResult.result === 1;

    if (isWin) {
        // Calculate profit for a winning bet
        betProfit = currentBetStake * (runnerResult.currentOdds - 1);
        // Round betProfit to the nearest 50 cents
        betProfit = Math.round(betProfit * 2) / 2;

        setMaxBankroll(prevBankroll => {
            const newBankroll = prevBankroll + betProfit; // Add winnings to bankroll
            setBetsWon(bw => bw + 1);
            setNetProfit(np => np + betProfit); // Only add profit, not stake
            return newBankroll;
        });
        betResultType = 1;

        // Reset consecutive losses based on win odds
        const reduction = Math.floor(runnerResult.currentOdds / 2);
        
        setConsecutiveLosses(prev => Math.max(0, prev - reduction));
    } else {
        // Deduct stake for a losing bet
        setMaxBankroll(prevBankroll => {
            const newBankroll = prevBankroll - currentBetStake; // Deduct stake only if lost
            setBetsLost(bl => bl + 1);
            setNetProfit(np => np - currentBetStake);
            return newBankroll;
        });
        betResultType = 0;

        // Increment consecutive losses
        setConsecutiveLosses(prev => prev + 1);
    }

    // Stop betting if consecutive losses exceed the limit
    if (consecutiveLosses >= lossLimit) {
      console.log('Hmm Stopping betting due to consecutive losses');
    }

    // Update minimum bankroll if current bankroll is lower
    if (maxBankroll < minBankroll) {
        setMinBankroll(maxBankroll);
    }

    // Stake Increase Logic - check if profit threshold is met AFTER bet is settled
    const currentProfitPercent = ((maxBankroll - minBankroll) / minBankroll) * 100;
    if (currentProfitPercent >= profitThresholdForStakeIncrease) {
        setBetStakePercentage(profitStakeIncreasePercentage); // Increase stake percentage
        setProfitThresholdForStakeIncrease(prevThreshold => prevThreshold + 10); // Increase threshold for next stake increase
    }

    // Reserve and Reinvest Logic - apply AFTER bet is settled and potential stake increase
    if (betProfit > 0) { // Only apply to winning bets
        setReserves(prevReserves => prevReserves + (betProfit * (reservePercentage / 100)));
        setMaxBankroll(prevBankroll => prevBankroll + (betProfit * (reinvestPercentage / 100))); // Reinvest a portion immediately
    }

    // Stop Loss Check - needs to be refined with buffer logic if needed
    const bankrollDropPercentage = ((minBankroll - maxBankroll) / minBankroll) * 100;
    if (bankrollDropPercentage >= stopLossPercentage) {
        // Implement buffer/pause logic here if needed - e.g., pause betting, require manual reset, etc.
    }

    // Update data with the result of the bet
    setData((prev) => [...prev, {...runnerResult, betStake: currentBetStake.toFixed(2), betWonAmount: betProfit.toFixed(2), betResultType }]);

    updateRaceMetrics(runnerResult);

    // Generate and update bet strings
    generateBetStrings(runnerResult, currentBetStake);
  };

  // Function to generate bet strings
  const generateBetStrings = (runnerResult: RunnerResultType, currentBetStake: number) => {
    const venueCode = `${runnerResult.sellCode.meetingCode}${runnerResult.sellCode.scheduledType}`;
    const raceNumber = String(runnerResult.raceNumber).padStart(2, '0');
    const selection = runnerResult.runnerNumber;

    // Calculate amount1 and amount2 based on currentBetStake
    const amount1 = formatAmount(currentBetStake / 2); // Half for WIN
    const amount2 = formatAmount(currentBetStake / 2); // Half for PLACE (if Each Way)

    // Include bet type in the bet string
    const newBetString = `${venueCode}-${raceNumber}-${betType}-${amount1}-${amount2}/${selection}/`;
    setBetStrings((prevStrings) => `${prevStrings}\n${newBetString}`);
  };


  // Toggle selection of a runner by index (unchanged)
  const toggleSelectRunner = (index: number) => {
    setSelectedRows((prev) => {
      const next = new Set(prev);
      if (next.has(index)) {
        next.delete(index);
      } else {
        next.add(index);
      }
      return next;
    });
  };

  // Select all runners in a given race number (unchanged)
  const selectAllInRace = (raceNumber: number) => {
    const newSelected = new Set<number>();
    data.forEach((runner, index) => {
      if (runner.raceNumber === raceNumber) {
        newSelected.add(index);
      }
    });
    setSelectedRows(newSelected);
  };

  // Emit event with selected runner data (unchanged)
  const askTabyAboutSelectedRunners = () => {
    const selectedData = Array.from(selectedRows).map((idx) => {
      const runner = data[idx];
      return {
        venue: runner.meetingName, // Assuming meetingName corresponds to venue
        raceNumber: runner.raceNumber,
        meetingName: runner.meetingName,
        // Add any other necessary properties here if needed
      };
    });
    console.log(selectedData);
    console.log(data);
    eventEmitter.emit('FETCH_TABY_PREDICTIONS', selectedData, data);
  };

  /* ------------------------------ React Table Setup - UPDATED COLUMNS ------------------------------ */
  const columns: any[] = useMemo(() => [
    {
      Header: 'Runner',
      accessor: 'runnerName',
      Cell: ({ row }: any) => (
        <RunnerNameCell>
          {row.original.runnerName} #{row.original.runnerNumber}
        </RunnerNameCell>
      ),
      sortType: 'basic',
    },
    {
      Header: 'Race Info',
      id: 'raceInfo',
      Cell: ({ row }: any) => (
        <MeetingRaceCell>
          Race {row.original.raceNumber} - {row.original.meetingName}
        </MeetingRaceCell>
      ),
    },
    {
      Header: 'Current Odds',
      accessor: 'currentOdds',
      Cell: ({ value, row }: any) => {
        const fairOdds = 1 / row.original.probabilityScore;
        const overlay = row.original.currentOdds - fairOdds;
        const isValueBet = overlay > 0;
        return <OddsCell isValueBet={isValueBet}>{value.toFixed(2)}</OddsCell>;
      },
      sortType: 'basic',
    },
    {
      Header: 'Fair Odds',
      id: 'fairOdds',
      Cell: ({ row }: any) => {
        const fairOdds = 1 / row.original.probabilityScore;
        const roundedFairOdds = Math.round(fairOdds * 2) / 2; // Round to nearest 0.5
        return roundedFairOdds.toFixed(2);
      },
      sortType: 'basic',
    },
    {
      Header: 'Overlay',
      id: 'overlay',
      Cell: ({ row }: any) => {
        const fairOdds = 1 / row.original.probabilityScore;
        const overlay = row.original.currentOdds - fairOdds;
        return (
          <OverlayCell isPositive={overlay > 0}>
            {overlay.toFixed(2)}
          </OverlayCell>
        );
      },
      sortType: 'basic',
    },
    {
      Header: 'Probability/Stability',
      accessor: 'probabilityScore',
      Cell: ({ value }: any) => <div>{value.toFixed(2)}</div>,
    },
    {
      Header: 'Average Pace',
      accessor: 'averagePace',
      Cell: ({ value }: any) => <div>{value.toFixed(2)}</div>,
    },
    {
      Header: 'Bet Stake',
      accessor: 'betStake',
      Cell: ({ value }: any) => <BetAmountCell>${value}</BetAmountCell>,
    },
    {
      Header: 'Bet Result',
      accessor: 'betResultType',
      Cell: ({ value }: any) => <ResultCell result={value}>{value === 1 ? 'Win' : value === 2 ? 'Place' : 'Loss'}</ResultCell>,
      filter: 'equals', // Enable filtering by result
    },
    {
      Header: 'Bet Won Amount',
      accessor: 'betWonAmount',
      Cell: ({ value, row }: any) => <BetAmountCell>${row.original.betResultType === 1 || row.original.betResultType === 2 ? value : '0.00'}</BetAmountCell>,
    },
    {
      Header: 'Result',
      accessor: 'result',
      Cell: ({ value }: any) => <ResultCell result={value}>{value === 1 ? '1st' : value === 2 ? '2nd' : value === 3 ? '3rd' : '-'}</ResultCell>,
      filter: 'equals', // Enable filtering by result
    },
    {
      Header: 'Recomm. Bet',
      id: 'recommendedBet',
      Cell: ({ row }: any) => {
        const originalRow = row.original;
        const fairOdds = 1 / originalRow.probabilityScore;
        const overlay = originalRow.currentOdds - fairOdds;
        const isValueBet = overlay > 0;
        const recommendedBet = isValueBet ? (maxBankroll * (betStakePercentage / 100)) : 0;
        return isValueBet ? <BetAmountCell>${recommendedBet.toFixed(2)}</BetAmountCell> : '-';
      },
      disableSortBy: true,
    },
    {
      Header: 'Select',
      id: 'select',
      disableSortBy: true,
      Cell: ({ row }: any) => (
        <input
          type="checkbox"
          style={{ margin: '0 auto', display: 'block' }} // Center checkbox
          checked={selectedRows.has(row.index)}
          onChange={() => toggleSelectRunner(row.index)}
        />
      ),
    },
    // Add more columns here to display all values in the runner data
    {
      Header: 'Additional Info',
      accessor: 'additionalInfo',
      Cell: ({ row }: any) => <div>{JSON.stringify(row.original)}</div>,
    },
  ], [selectedRows, maxBankroll, betStakePercentage]);

  // Setup table instance with sorting, filtering, and row selection hooks (unchanged)
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {

      }
    },
    useGlobalFilter,
    useSortBy,
    useRowSelect
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  // Function to calculate and display most profitable meetings
  const getMostProfitableMeetings = () => {
    return Object.entries(meetingROIs)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 5)
      .map(([meeting]) => meeting);
  };

  // Function to handle bankroll reset
  const handleResetBankroll = () => {
    setMaxBankroll(initialBankroll);
    setMinBankroll(initialBankroll);
    resetMetrics();
  };

  return (
    <BankrollContainer>
      <ResetButton onClick={handleResetBankroll}>Reset Bankroll</ResetButton>
      <BankrollHeader>Current Bankroll: ${maxBankroll.toFixed(2)}</BankrollHeader>

      {/* Charts Section */}
      <ChartsContainer>
        <ChartSection>
          <ChartTitle>Wins</ChartTitle>
          <ChartValue>{winCount}</ChartValue>
          <ChartLabel>Total Wins</ChartLabel>
        </ChartSection>
        <ChartSection>
          <ChartTitle>Losses</ChartTitle>
          <ChartValue>{lossCount}</ChartValue>
          <ChartLabel>Total Losses</ChartLabel>
        </ChartSection>
        <ChartSection>
          <ChartTitle>Avg. Odds</ChartTitle>
          <ChartValue>{avgOdds.toFixed(2)}</ChartValue>
          <ChartLabel>Average Current Odds</ChartLabel>
        </ChartSection>
        <ChartSection>
          <ChartTitle>Win %</ChartTitle>
          <ChartValue>{winPercentage.toFixed(2)}%</ChartValue>
          <ChartLabel>Win Rate</ChartLabel>
        </ChartSection>
        <ChartSection>
          <ChartTitle>ROI</ChartTitle>
          <ChartValue>{roi.toFixed(2)}%</ChartValue>
          <ChartLabel>Return on Investment</ChartLabel>
        </ChartSection>
      </ChartsContainer>    

      <ActionButton onClick={askTabyAboutSelectedRunners}>
        Ask TABY About Selected Runners
      </ActionButton>
      <GlobalFilterInput
        value={globalFilter}
        onChange={(e) => {
          setGlobalFilter(e.target.value);
        }}
        placeholder="Search runners, race, meeting..."
      />
      <TableContainer>
        <StyledTable {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup : any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column : any) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row : any) => {
              prepareRow(row);
              const isWinner = row.original.result === 1;
              return (
                <tr
                  {...row.getRowProps()}
                  style={{
                    backgroundColor: isWinner ? 'rgba(255, 215, 0, 0.1)' : 'inherit', // very subtle gold for wins
                  }}
                >
                  {row.cells.map((cell : any) => (
                    <td {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      </TableContainer>
          {/* Textarea for Displaying Bet Strings */}
      <BetStringTextarea
        value={betStrings}
        readOnly
        placeholder="Generated bet strings will appear here..."
      />
    </BankrollContainer>
  );
});

export default Bankroll2;