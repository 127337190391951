type EventCallback = (event: string, data: any, context: any) => void;

class EventEmitter {
  private events: { [key: string]: EventCallback[] } = {};

  on(event: string, callback: EventCallback) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(callback);
  }

  off(event: string, callback: EventCallback) {
    if (!this.events[event]) return;
    this.events[event] = this.events[event].filter(cb => cb !== callback);
  }

  emit(event: string, data: any, context: any) {
    if (!this.events[event]) return;
    this.events[event].forEach(callback => callback(event, data, context));
  }
}

const eventEmitter = new EventEmitter();
export default eventEmitter;