// DesktopBridgeContext.tsx
import React, { createContext } from 'react';
import { WindowInfo } from '../components/Windows';

export interface DesktopBridge {
  launchProgram: (progId: string, initialData?: any) => void;
  killProcess: (pid: string) => void;
  focusWindow: (windowId: string) => void;
  getWindows: () => WindowInfo[];
  // Additional functions can be added as needed
}

export const DesktopBridgeContext = createContext<DesktopBridge | null>(null);
