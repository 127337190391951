/*************************************************************************************************
 * Windows.tsx
 *
 * The main "desktop" environment that:
 *  - Renders a stylized background.
 *  - Manages "programs" and their windows.
 *  - Has a program panel (moved from the bottom to the top left) for open programs.
 *  - Demonstrates usage of our TabyWizardNoClassic + OperatorBridge as "programs" plus two chat apps and a combined chat.
 *************************************************************************************************/

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { GeminiChat } from '../agents/gemini_chat';
import { OperatorBridge } from '../agents/operator_bridge';
import { Taby } from '../Taby/Taby';
import Bankroll from '../Bankroll';
import { AgentInterface } from '../agents/taby_chat_agent';
import { DesktopBridgeContext } from '../../context/WindowBridge';
import { WindowWrapper, TitleBar, TitleText, WindowButtonContainer, WindowButton, WindowContent, TaskbarContainer, TaskbarItem, DesktopContainer, DesktopIcon, IconContainer, IconLabel } from '../../globalStyles';
import styled, { ThemeProvider } from 'styled-components';
import App from '../../App';
import Bankroll2 from '../Bankroll/bankroll2';
import DigitalClock from '../DigitalClock';
import ThemeToggle from '../ThemeToggle';
import QuickLaunch from '../QuickLaunch';
import StartMenu from '../StartMenu';

/* ------------------------------ Types ------------------------------ */
export interface ProgramDefinition {
  id: string;
  displayName: string;
  iconUrl: string;
  allowMultiple: boolean;
  compiledComponent?: React.ComponentType<any>;
}

export interface ProcessInfo {
  pid: string;
  programId: string;
  startTime: number;
  logs: string[];
  isActive: boolean;
}

export interface WindowInfo {
  windowId: string;
  processId: string;
  programId: string;
  title: string;
  iconUrl: string;
  component: React.ReactNode;
  x: number;
  y: number;
  width: number;
  height: number;
  zIndex: number;
  minimized: boolean;
  docked: boolean;
  dockPosition?: 'left' | 'right' | 'bottom';
}

export interface IconGroup {
  id: string;
  title: string;
  icons: ProgramDefinition[];
  x: number;
  y: number;
}

// FullscreenSwitcher Component: Provides an intuitive UI for switching windows in fullscreen mode
interface FullscreenSwitcherProps {
  windows: WindowInfo[];
  fullscreenWindowId: string;
  onWindowClick: (windowId: string) => void;
}

const FullscreenSwitcher: React.FC<FullscreenSwitcherProps> = ({ windows, fullscreenWindowId, onWindowClick }) => {
  return (
    <div style={{
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      padding: '10px',
      zIndex: 3000,
      display: 'flex',
      justifyContent: 'center',
      gap: '20px'
    }}>
      {windows.map(win => (
        <div
          key={win.windowId}
          onClick={() => onWindowClick(win.windowId)}
          style={{
            cursor: 'pointer',
            textAlign: 'center',
            color: win.windowId === fullscreenWindowId ? '#fff' : '#aaa'
          }}
          title={win.title}
        >
          <div style={{ fontSize: '2em' }}>{win.iconUrl}</div>
          <div>{win.title}</div>
        </div>
      ))}
    </div>
  );
};

/* ------------------------------ Main Component ------------------------------ */
export const Windows: React.FC = () => {
  const [programs, setPrograms] = useState<ProgramDefinition[]>([
    {
      id: 'OperatorBridge',
      displayName: 'Operator Bridge',
      iconUrl: '🧠',
      allowMultiple: true,
      compiledComponent: OperatorBridge,
    },
    {
      id: 'TabyChat',
      displayName: 'TABY Agent',
      iconUrl: '💬',
      allowMultiple: true,
      compiledComponent: AgentInterface,
    },
    {
      id: 'TabyWizardNoClassic',
      displayName: 'Taby',
      iconUrl: '📊',
      allowMultiple: true,
      compiledComponent: Taby,
    },
    {
      id: 'bankroll',
      displayName: 'Bankroll',
      iconUrl: '💰',
      allowMultiple: true,
      compiledComponent: Bankroll2,
    }
  ]);

  const [processes, setProcesses] = useState<ProcessInfo[]>([]);
  const [windows, setWindows] = useState<WindowInfo[]>([]);
  const [dockedWindows, setDockedWindows] = useState<WindowInfo[]>([]);
  const [topZ, setTopZ] = useState<number>(100);
  const pidCounter = useRef(1);
  const winCounter = useRef(1);

  const [iconGroups, setIconGroups] = useState<IconGroup[]>([
    {
      id: 'systemGroup',
      title: 'System',
      icons: [
        {
          id: 'newProgram1',
          displayName: 'New Program 1',
          iconUrl: '🆕',
          allowMultiple: true,
          compiledComponent: GeminiChat,
        },
        {
          id: 'App',
          displayName: 'App',
          iconUrl: '🔧',
          allowMultiple: false,
          compiledComponent: App,
        },
      ],
      x: 5,
      y: 5,
    },
    {
      id: 'tabyGroup',
      title: 'TABY',
      icons: programs,
      x: 200,
      y: 200,
    },
    // Add more groups as needed
  ]);

  // State for managing the theme (light/dark) to enhance user experience
  const [theme, setTheme] = useState<'light' | 'dark'>('light');

  // Add state for controlling Start Menu visibility
  const [startMenuOpen, setStartMenuOpen] = useState<boolean>(false);

  // Function to toggle the theme
  const toggleTheme = () => {
    setTheme(prevTheme => prevTheme === 'light' ? 'dark' : 'light');
  };

  // Define grid size for snapping
  const GRID_SIZE = 20;
  // Define the reserved height for the taskbar at the top
  const TASKBAR_HEIGHT = 40;

  // Function to snap a value to the nearest grid point
  const snapToGrid = (value: number, gridSize: number) => {
    return Math.round(value / gridSize) * gridSize;
  };

  // Create the actual program UI element
  function createProgramComponent(prog: ProgramDefinition, pid: string): React.ReactNode {
    if (prog.compiledComponent) {
      const Comp = prog.compiledComponent;
      return <Comp />;
    }
    return <div>Unknown program {prog.id}</div>;
  }

  function focusWindow(windowId: string) {
    setWindows(prev =>
      prev.map(w => (w.windowId === windowId ? { ...w, zIndex: topZ + 1 } : w))
    );
    setTopZ(z => z + 2);
  }

  function focusWindowByProcessId(pid: string) {
    const winToFocus = windows.find(w => w.processId === pid);
    if (winToFocus) {
      focusWindow(winToFocus.windowId);
    }
  }

  const launchProgram = useCallback((programId: string) => {
    // Combine all programs from icon groups into a single list
    const allPrograms = iconGroups.reduce<ProgramDefinition[]>((acc, group) => {
      return acc.concat(group.icons);
    }, []);

    // Find the program in the combined list
    const program = allPrograms.find(prog => prog.id === programId);
    if (!program) {
      console.error(`Program with ID ${programId} not found.`);
      return;
    }

    // Check if multiple instances are allowed
    if (!program.allowMultiple) {
      const existingProcess = processes.find(proc => proc.programId === programId);
      if (existingProcess) {
        focusWindowByProcessId(existingProcess.pid);
        return;
      }
    }

    const newPid = `pid-${pidCounter.current++}`;
    const newWinId = `win-${winCounter.current++}`;

    const newProcess: ProcessInfo = {
      pid: newPid,
      programId: programId,
      startTime: Date.now(),
      logs: [],
      isActive: true,
    };

    const newWindow: WindowInfo = {
      windowId: newWinId,
      processId: newPid,
      programId: programId,
      title: program.displayName,
      iconUrl: program.iconUrl,
      component: createProgramComponent(program, newPid),
      x: 100,
      y: 100,
      width: 1024,
      height: 768,
      zIndex: topZ + 1,
      minimized: false,
      docked: false,
    };

    setProcesses(prev => [...prev, newProcess]);
    setWindows(prev => [...prev, newWindow]);
    setTopZ(z => z + 2);
  }, [iconGroups, processes, setProcesses, setWindows, setTopZ]);

  // Return current window state (for bridging purposes)
  function getWindows() {
    return windows;
  }

  // Window event handlers
  function closeWindow(windowId: string) {
    const w = windows.find(win => win.windowId === windowId);
    if (!w) return;
    killProcess(w.processId);
  }

  function killProcess(pid: string) {
    setProcesses(prev => prev.filter(p => p.pid !== pid));
    setWindows(prev => prev.filter(w => w.processId !== pid));
  }

  function minimizeWindow(windowId: string) {
    setWindows(prev =>
      prev.map(w => (w.windowId === windowId ? { ...w, minimized: !w.minimized } : w))
    );
  }

  // Modify the onDragWindow function to include snapping and to never allow dragging into the reserved panel area.
  function onDragWindow(windowId: string, newX: number, newY: number) {
    setWindows(prev =>
      prev.map(w => 
        w.windowId === windowId 
          ? { ...w, x: snapToGrid(newX, GRID_SIZE), y: Math.max(snapToGrid(newY, GRID_SIZE), TASKBAR_HEIGHT) } 
          : w
      )
    );
  }

  // Function to handle docking logic
  function dockWindow(windowId: string, position: 'left' | 'right' | 'bottom') {
    setWindows(prev => prev.filter(w => w.windowId !== windowId));
    setDockedWindows(prev => [
      ...prev,
      { ...windows.find(w => w.windowId === windowId)!, docked: true, dockPosition: position }
    ]);
  }

  // Function to undock a window
  function undockWindow(windowId: string) {
    setDockedWindows(prev => prev.filter(w => w.windowId !== windowId));
    setWindows(prev => [
      ...prev,
      { ...dockedWindows.find(w => w.windowId === windowId)!, docked: false }
    ]);
  }

  function resizeWindow(windowId: string, newWidth: number, newHeight: number) {
    setWindows(prevWindows =>
      prevWindows.map(win =>
        win.windowId === windowId
          ? { ...win, width: newWidth, height: newHeight }
          : win
      )
    );
  }

  // Function to handle dragging of icon groups
  function onDragGroup(groupId: string, newX: number, newY: number) {
    setIconGroups(prev =>
      prev.map(g => (g.id === groupId ? { ...g, x: newX, y: newY } : g))
    );
  }

  // Function to add a new program
  function addNewProgram(newProgram: ProgramDefinition) {
    setPrograms(prev => [...prev, newProgram]);
  }

  const handleWindowClick = (windowId: string) => {
    focusWindow(windowId);
  };

  // Update the desktopIcons to render based on iconGroups
  const desktopIcons = iconGroups.map(group => (
    <IconGroupComponent
      key={group.id}
      group={group}
      onDrag={(x, y) => onDragGroup(group.id, x, y)}
      launchProgram={launchProgram}
    />
  ));

  const allPrograms = iconGroups.reduce<ProgramDefinition[]>((acc, group) => acc.concat(group.icons), []);

  // Define theme object based on current theme state
  const themeObject = theme === 'light'
    ? { primaryColor: "#007bff", backgroundColor: "#f5f5f5", textColor: "#202124", borderColor: "#DADCE0", shadowColor: "rgba(0,0,0,0.2)", fontFamily: '"-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif"' }
    : { primaryColor: "#007bff", backgroundColor: "#222", textColor: "#fff", borderColor: "#444", shadowColor: "rgba(0,0,0,0.8)", fontFamily: '"-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif"' };

  return (
    <ThemeProvider theme={themeObject}>
      <DesktopBridgeContext.Provider value={{ launchProgram, killProcess, focusWindow, getWindows }}>
        <DesktopContainer>
          {/* Fullscreen Start Menu now uses a transparent overlay and closes when clicking outside */}
          {startMenuOpen && (
            <FullScreenStartMenu onClick={() => setStartMenuOpen(false)}>
              <FullScreenStartMenuContent onClick={(e) => e.stopPropagation()}>
                <StartMenu
                  programs={allPrograms}
                  launchProgram={launchProgram}
                  theme={theme}
                  toggleTheme={toggleTheme}
                  onClose={() => setStartMenuOpen(false)}
                />
              </FullScreenStartMenuContent>
            </FullScreenStartMenu>
          )}

          <div style={{ position: 'absolute', top: '10%', width: '100%', textAlign: 'center', opacity: 0.1, fontSize: '5em' }}>
            TABY
          </div>

          {desktopIcons}
          {windows.map(win => (
            <DesktopWindow
              key={win.windowId}
              wInfo={win}
              onClose={() => closeWindow(win.windowId)}
              onFocus={() => focusWindow(win.windowId)}
              onMinimize={() => minimizeWindow(win.windowId)}
              onDrag={(x, y) => onDragWindow(win.windowId, x, y)}
              onDock={dockWindow}
              onResize={resizeWindow}
            />
          ))}
          {dockedWindows.map(win => (
            <DockedPanel
              key={win.windowId}
              wInfo={win}
              onUndock={() => undockWindow(win.windowId)}
            />
          ))}
          {/* Render the Program Panel (formerly the bottom Taskbar) in the top left corner */}
          <Taskbar 
            windows={windows} 
            onWindowClick={winId => focusWindow(winId)} 
            onWindowMinimize={winId => minimizeWindow(winId)}
            onStartClick={() => setStartMenuOpen(prev => !prev)}
          />
        </DesktopContainer>
      </DesktopBridgeContext.Provider>
    </ThemeProvider>
  );
};

/* ------------------------------ DesktopWindow Component ------------------------------ */
interface DesktopWindowProps {
  wInfo: WindowInfo;
  onClose: () => void;
  onFocus: () => void;
  onMinimize: () => void;
  onDrag: (x: number, y: number) => void;
  onDock: (windowId: string, position: 'left' | 'right' | 'bottom') => void;
  onResize: (windowId: string, newWidth: number, newHeight: number) => void;
}

const DesktopWindow: React.FC<DesktopWindowProps> = ({
  wInfo,
  onClose,
  onFocus,
  onMinimize,
  onDrag,
  onDock,
  onResize,
}) => {
  const { x, y, width, height, zIndex, minimized, title, iconUrl, component } = wInfo;
  const [dragging, setDragging] = useState(false);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!dragging) return;
      onDrag(e.clientX - offsetX, e.clientY - offsetY);
    };

    const handleMouseUp = () => {
      setDragging(false);
    };

    if (dragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging, offsetX, offsetY, onDrag]);

  if (minimized) return null;

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    onFocus();
    setDragging(true);
    setOffsetX(e.clientX - x);
    setOffsetY(e.clientY - y);
  };

  const handleResize = (newWidth: number, newHeight: number) => {
    onResize(wInfo.windowId, newWidth, newHeight);
  };

  const handleDock = (position: 'left' | 'right' | 'bottom') => {
    onDock(wInfo.windowId, position);
  };

  return (
    <>
      {dragging && <WindowGridOverlay />}
      <WindowWrapper
        x={x}
        y={y}
        width={width}
        height={height}
        zIndex={zIndex}
        onMouseDown={onFocus}
      >
        <TitleBar onMouseDown={handleMouseDown}>
          <TitleText>{iconUrl} {title}</TitleText>
          <WindowButtonContainer>
            <WindowButton onClick={(e) => { e.stopPropagation(); onMinimize(); }}>_</WindowButton>
            <WindowButton onClick={(e) => { e.stopPropagation(); onClose(); }}>✖</WindowButton>
          </WindowButtonContainer>
        </TitleBar>
        <WindowContent>
          {component}
          <ResizeHandle onResize={handleResize} initialWidth={width} initialHeight={height} />
          <DockHandle onDock={handleDock} />
        </WindowContent>
      </WindowWrapper>
    </>
  );
};

/* ------------------------------ DockedPanel Component ------------------------------ */
interface DockedPanelProps {
  wInfo: WindowInfo;
  onUndock: () => void;
}

const DockedPanel: React.FC<DockedPanelProps> = ({ wInfo, onUndock }) => {
  const { title, iconUrl, component, dockPosition } = wInfo;

  return (
    <DockedPanelWrapper position={dockPosition}>
      <TitleBar>
        <TitleText>{iconUrl} {title}</TitleText>
        <WindowButton onClick={onUndock}>Undock</WindowButton>
      </TitleBar>
      <WindowContent>{component}</WindowContent>
    </DockedPanelWrapper>
  );
};

/* ------------------------------ Program Panel (Taskbar) Component ------------------------------ */
interface TaskbarProps {
  windows: WindowInfo[];
  onWindowClick: (winId: string) => void;
  onWindowMinimize: (winId: string) => void;
}
interface ExtendedTaskbarProps extends TaskbarProps {
  onStartClick: () => void;
}

const Taskbar: React.FC<ExtendedTaskbarProps> = ({ windows, onWindowClick, onWindowMinimize, onStartClick }) => {
  return (
    <TaskbarContainerStyled>
      <StartButton onClick={(e) => { e.stopPropagation(); onStartClick(); }} title="Open Start Menu">
        <span role="img" aria-label="start">🪟</span>
      </StartButton>
      {windows.map(w => (
        <TaskbarItemStyled
          key={w.windowId}
          onClick={() => onWindowClick(w.windowId)}
          onContextMenu={(e) => { e.preventDefault(); onWindowMinimize(w.windowId); }}
          title="Click to focus, Right-click to minimize"
        >
          <span>{w.iconUrl}</span>
        </TaskbarItemStyled>
      ))}
    </TaskbarContainerStyled>
  );
};

// ------------------- Updated Taskbar (Dock) Styling -------------------
const TaskbarContainerStyled = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  min-width: 300px;
  background: linear-gradient(135deg, rgba(0,255,255,0.8), rgba(255,20,147,0.8));
  box-shadow: 0 0 15px rgba(0,255,255,0.7), 0 0 20px rgba(255,20,147,0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  z-index: 6000;
  border-radius: 10px;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    height: 50px;
    padding: 0 5px;
  }
`;

// ------------------- Updated Start Button for a Futuristic Look -------------------
const StartButton = styled.button`
  background-color: rgba(0,255,255,0.9);
  border: none;
  color: ${props => props.theme.backgroundColor || '#f5f5f5'};
  padding: 8px 12px;
  font-size: 20px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
  box-shadow: 0 0 15px rgba(0,255,255,0.7);
  &:hover {
    background-color: rgba(255,20,147,0.9);
  }
`;

const TaskbarItemStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
  margin-left: 8px;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: ${props => props.theme.hoverItemColor || '#666'};
  }
  span {
    font-size: 18px;
  }
`;

// ------------------------------ DockHandle Component ------------------------------
const DockHandle: React.FC<{ onDock: (position: 'left' | 'right' | 'bottom') => void }> = ({ onDock }) => {
  return (
    <div style={{
      position: 'absolute',
      bottom: 0,
      right: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      cursor: 'pointer',
      background: 'rgba(0,0,0,0.1)',
      padding: '4px',
      borderRadius: '4px'
    }}>
      <button style={{ fontSize: '0.8em', padding: '4px', cursor: 'pointer' }} onClick={() => onDock('left')} title="Dock to Left">⇦</button>
      <button style={{ fontSize: '0.8em', padding: '4px', cursor: 'pointer' }} onClick={() => onDock('right')} title="Dock to Right">⇨</button>
      <button style={{ fontSize: '0.8em', padding: '4px', cursor: 'pointer' }} onClick={() => onDock('bottom')} title="Dock to Bottom">⇩</button>
    </div>
  );
};

// ------------------------------ ResizeHandle Component ------------------------------
interface ResizeHandleProps {
  onResize: (newWidth: number, newHeight: number) => void;
  initialWidth: number;
  initialHeight: number;
}

const ResizeHandle: React.FC<ResizeHandleProps> = ({ onResize, initialWidth, initialHeight }) => {
  const handleMouseDown = (e: React.MouseEvent) => {
    const startX = e.clientX;
    const startY = e.clientY;

    const handleMouseMove = (moveEvent: MouseEvent) => {
      const deltaX = moveEvent.clientX - startX;
      const deltaY = moveEvent.clientY - startY;
      const newWidth = initialWidth + deltaX;
      const newHeight = initialHeight + deltaY;
      onResize(Math.max(100, newWidth), Math.max(100, newHeight));
    };

    const handleMouseUp = () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: '20px',
        height: '20px',
        backgroundColor: 'gray',
        cursor: 'nwse-resize',
      }}
      onMouseDown={handleMouseDown}
    />
  );
};

// ------------------------------ DockedPanelWrapper ------------------------------
const DockedPanelWrapper = styled.div<{ position?: 'left' | 'right' | 'bottom' }>`
  position: fixed;
  ${(props) => props.position === 'left' && 'left: 0;'}
  ${(props) => props.position === 'right' && 'right: 0;'}
  ${(props) => props.position === 'bottom' && 'bottom: 0;'}
  width: 640px;
  height: 100%;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  overflow: auto;
  z-index: 1000;
  resize: both;
`;

// ------------------- IconGroupComponent -------------------
const IconGroupComponent: React.FC<{ group: IconGroup; onDrag: (x: number, y: number) => void; launchProgram: (programId: string) => void }> = ({ group, onDrag, launchProgram }) => {
  const { x, y, title, icons } = group;
  const [dragging, setDragging] = useState(false);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!dragging) return;
      onDrag(e.clientX - offsetX, e.clientY - offsetY);
    };

    const handleMouseUp = () => {
      setDragging(false);
    };

    if (dragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging, offsetX, offsetY, onDrag]);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setDragging(true);
    setOffsetX(e.clientX - x);
    setOffsetY(e.clientY - y);
  };

  return (
    <>
      {dragging && <GridOverlay />}
      <div style={{
        position: 'absolute',
        left: x,
        top: y,
        border: '0px solid #ccc',
        padding: '10px',
        textAlign: 'center',
        backgroundColor: 'transparent'
      }}
        onMouseDown={handleMouseDown}
      >
        <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>{title}</div>
        {icons.map(icon => (
          <DesktopIcon
            key={icon.id}
            onDoubleClick={() => launchProgram(icon.id)}
            title={`Open ${icon.displayName}`}
          >
            <div style={{ fontSize: 32 }}>{icon.iconUrl}</div>
            <IconLabel>{icon.displayName}</IconLabel>
          </DesktopIcon>
        ))}
      </div>
    </>
  );
};

// ------------------- Updated FullScreen Start Menu -------------------
const FullScreenStartMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  box-shadow: 0 0 30px rgba(0,255,255,0.5);
`;

const FullScreenStartMenuContent = styled.div`
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(45deg, rgba(0,255,255,0.9), rgba(255,20,147,0.9));
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(255,20,147,0.7);
`;

// ------------------- Updated Grid Overlays with Neon Drop Shadows -------------------
const GridOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  background-image: 
    linear-gradient(to right, rgba(0, 255, 255, 0.3) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255,20,147,0.3) 1px, transparent 1px);
  background-size: 20px 20px;
  z-index: 9500;
  filter: drop-shadow(0 0 5px rgba(0, 255, 255, 0.5));
`;

const WindowGridOverlay = styled.div`
  position: fixed;
  top: 60px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 60px);
  pointer-events: none;
  background-image: 
    linear-gradient(to right, rgba(0, 255, 255, 0.3) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255,20,147,0.3) 1px, transparent 1px);
  background-size: 20px 20px;
  z-index: 9500;
  filter: drop-shadow(0 0 5px rgba(255,20,147,0.5));
`;

export default Windows;
