/*************************************************************************************************
 * TabyChat.tsx
 *
 * Enhanced user interface for interacting with the TABY AI Agent.
 * This component is fully state aware—it loads and persists the bankroll, the chat log,
 * and via the DesktopBridgeContext it can launch or control programs in the desktop.
 *************************************************************************************************/

import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { useTaby } from "../../providers/tabyProvider";
import { Bankroll } from "../../interfaces/Bankroll";
import { DesktopBridgeContext } from "../../context/WindowBridge";
import ApiService, { apiService } from "../../services/api.taby";
import { EventEmitter } from 'events';
import { generateUUID } from "../agents/operator_taby";
import { ChatContainer, ChatLog, InputField, Button } from "../../globalStyles";

const CHAT_LOG_STORAGE_KEY = "TABY_CHAT_LOG";

// Assuming you have an event emitter instance
const eventEmitter = new EventEmitter();



export function TabyChat() {
  const [message, setMessage] = useState<string>("");
  const [chatLog, setChatLog] = useState<string[]>([]);
  const [bankrollInput, setBankrollInput] = useState<Partial<Bankroll>>({});
  const [bankrollPrompt, setBankrollPrompt] = useState<boolean>(false);
  const chatLogRef = useRef<HTMLDivElement>(null);

  const { determineBestAction, } = useTaby();
  const desktopBridge = useContext(DesktopBridgeContext);

  // Load stored bankroll and chat log
  useEffect(() => {
    const storedChatLog = sessionStorage.getItem(CHAT_LOG_STORAGE_KEY);
    if (storedChatLog) {
      setChatLog(JSON.parse(storedChatLog));
    }
  }, []);

  // Persist chat log and scroll
  useEffect(() => {
    if (chatLogRef.current) {
      chatLogRef.current.scrollTop = chatLogRef.current.scrollHeight;
    }
    sessionStorage.setItem(CHAT_LOG_STORAGE_KEY, JSON.stringify(chatLog));
  }, [chatLog]);

  useEffect(() => {
    const handleFetchTabyPredictions = (selectedData: { venue: any; raceNumber: any; }, data: any) => {
      // Generate a prompt based on the selectedData and data
      const prompt = `Please provide your opinion on the following selections: ${JSON.stringify(selectedData)} with data: ${JSON.stringify(data)}`;
      
      // Prepare an assistant message with race information
      const raceInfo = `Race Info: Venue - ${selectedData.venue}, Race Number - ${selectedData.raceNumber}`;
      const assistantMessage = `Assistant: Here is the race information to help you with your analysis. ${raceInfo}`;

      // Send the generated prompt and assistant message as chat messages
      setChatLog((prevLog) => [
        ...prevLog,
        `User: ${prompt}`,
        assistantMessage
      ]);
      handleSendMessage(prompt);
    };

    // Listen for the FETCH_TABY_PREDICTIONS event
    eventEmitter.on('FETCH_TABY_PREDICTIONS', handleFetchTabyPredictions);

    // Cleanup the event listener on component unmount
    return () => {
      eventEmitter.off('FETCH_TABY_PREDICTIONS', handleFetchTabyPredictions);
    };
  }, []);

  // Process a message; in addition to determineBestAction, we support agent commands.
  const handleSendMessage = async (customMessage?: string) => {
    const messageToSend = customMessage || message;
    if (!messageToSend.trim()) return;
    setChatLog((prevLog) => [...prevLog, `User: ${messageToSend}`]);
    
    // Example: support simple commands such as "launch [programId]"
    if (messageToSend.startsWith("launch ") && desktopBridge) {
      const progId = messageToSend.replace("launch ", "").trim();
      desktopBridge.launchProgram(progId);
      setChatLog((prevLog) => [...prevLog, `TABY: Launched program ${progId}`]);
    } else if (messageToSend === "list windows" && desktopBridge) {
      const winList = desktopBridge.getWindows();
      setChatLog((prevLog) => [...prevLog, `TABY: Open windows: ${winList.map(w => w.title).join(", ")}`]);
    } else {
      // Fall back to AI action determination
      try {
        const response = await determineBestAction(messageToSend);
        setChatLog((prevLog) => [...prevLog, `TABY: ${response}`]);
      } catch (error) {
        console.error("Error processing message:", error);
        setChatLog((prevLog) => [...prevLog, "TABY: An error occurred while processing your message."]);
      }
    }
    setMessage("");
  };

  return (
    <ChatContainer>
      <h3>🏇 TABY AI Chat</h3>
      <ChatLog ref={chatLogRef}>
        {chatLog.map((entry, index) => (
          <p key={index} style={{ margin: 0 }}>{entry}</p>
        ))}
      </ChatLog>
      
      <div style={{ marginTop: 8 }}>
        <InputField
          type="text"
          placeholder="Type a message or command (e.g., 'launch TabyChat')..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
        />
        <Button onClick={() => handleSendMessage()}>Send</Button>
      </div>
      <div style={{ marginTop: 16 }}>
        <h4>🔹 Current Context:</h4>        
        {desktopBridge && (
          <div>
            <p>🖥 Open Windows: {desktopBridge.getWindows().length}</p>
          </div>
        )}
      </div>
    </ChatContainer>
  );
}
