import React, { useContext } from "react";
import Desktop from "./components/Windows";
import { WindowProvider } from "./context/WindowContext";

function App() {

  return <>
      <WindowProvider>
        <Desktop />
      </WindowProvider>
  </>
}

export default App;
