import React from "react";
import styled, { keyframes } from "styled-components";

// --- Consistent Color Palette ---
const primaryColor = "#00FFFF"; // Neon Blue
const secondaryColor = "#FF1493"; // Neon Pink
const backgroundColor = "#121212"; // Dark background
const textColor = "#E0E0E0";       // Light neon text
const borderColor = "rgba(255,20,147,0.5)"; // Neon pink border
const shadowColor = "rgba(0,255,255,0.5)"; // Neon blue glow

// --- Font ---
// Using Apple System Font Stack
const fontFamily = `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif`;

// --- Subtle Gradient & Animations ---
const subtleShimmer = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

// --- Global Styles ---
const GlobalButton = styled.button`
  padding: 10px 15px;
  font-size: 1rem;
  font-family: ${fontFamily};
  color: ${backgroundColor};
  background-color: ${primaryColor};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 0 15px ${shadowColor}, 0 0 20px ${secondaryColor};
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    background-color: ${secondaryColor};
    transform: scale(1.05);
    box-shadow: 0 0 25px ${shadowColor}, 0 0 30px ${secondaryColor};
  }

  &:active {
    transform: scale(1);
    box-shadow: 0 0 10px ${shadowColor};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0,255,255,0.5);
  }
`;


const neonPink = "#FF1493";
const neonPurple = "#9D00FF";
const neonBlue = "#00FFFF";
const darkBg = "#121212";
const lightText = "#E0E0E0";

// Background gradient animation for the desktop
const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;


const GlobalInput = styled.input`
  padding: 10px 12px;
  font-size: 1rem;
  font-family: ${fontFamily};
  color: ${textColor};
  background-color: ${backgroundColor};
  border: 1px solid ${borderColor};
  border-radius: 4px;
  box-shadow: 0 2px 3px ${shadowColor} inset;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${primaryColor};
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3); /* Focus ring */
  }
`;

const GlobalSelect = styled.select`
  padding: 10px 12px;
  font-size: 1rem;
  font-family: ${fontFamily};
  color: ${textColor};
  background-color: ${backgroundColor};
  border: 1px solid ${borderColor};
  border-radius: 4px;
  box-shadow: 0 2px 3px ${shadowColor} inset;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="${textColor}" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 8px);
  background-position-y: center;
  padding-right: 30px;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${primaryColor};
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.3); /* Focus ring */
  }
`;

const GlobalTitle = styled.h1`
  font-size: 2rem;
  margin: 0 0 20px 0;
  color: ${textColor};
  font-family: ${fontFamily};
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.05em;
`;

const GlobalSubTitle = styled.h2`
  font-size: 1.5rem;
  margin: 0 0 15px 0;
  color: #666;
  font-family: ${fontFamily};
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.03em;
`;

// --- Component Styles ---
const UserInfoContainer = styled.div`
  padding: 8px 12px;
  background-color: ${backgroundColor};
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  border: 1px solid ${borderColor};
  box-shadow: 0 2px 4px ${shadowColor};

  &:hover {
    transform: scale(1.03);
    box-shadow: 0 3px 6px ${shadowColor};
  }

  @media (max-width: 768px) {
    padding: 6px 10px;
    border-radius: 3px;
  }
`;

const UserName = styled.span`
  margin-left: 8px;
  color: ${textColor};
  font-family: ${fontFamily};
  font-weight: 500;
  font-size: 1rem;
`;

// Reusing GlobalButton for consistency
const Button = styled(GlobalButton)`
  margin-left: 4px;
`;

const LoginContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: ${backgroundColor};
  font-family: ${fontFamily};
`;

const LoginButton = styled(GlobalButton)`
  background-color: ${secondaryColor};

  &:hover {
    background-color: #D93025;
  }
`;

const Container = styled.div`
  max-width: 1024px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  font-family: ${fontFamily};
  background-color: ${backgroundColor};
  color: ${textColor};
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
`;

const Section = styled.div`
  padding: 20px;
  border: 1px solid ${borderColor};
  border-radius: 8px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.8),
    ${backgroundColor},
    rgba(255, 255, 255, 0.8)
  );
  background-size: 200% 200%;
  animation: ${subtleShimmer} 10s ease infinite;
  margin-bottom: 20px;
  color: ${textColor};
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 4px 6px ${shadowColor};
`;

const DashedSection = styled(Section)`
  border: 1px dashed ${borderColor};
`;

const Pill = styled.div<{ selected?: boolean }>`
  display: inline-block;
  padding: 8px 16px;
  margin: 5px;
  border-radius: 20px;
  background-color: ${(props) => (props.selected ? primaryColor : backgroundColor)};
  color: ${(props) => (props.selected ? backgroundColor : textColor)};
  border: 1px solid ${borderColor};
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s;
  font-weight: 500;
  box-shadow: 0 2px 4px ${shadowColor};

  &:hover {
    border-color: ${primaryColor};
    box-shadow: 0 3px 6px ${shadowColor};
    background-color: ${(props) => (props.selected ? "#0056b3" : backgroundColor)};
  }
`;

// Reusing GlobalInput for consistency
const Input = styled(GlobalInput)``;

// Reusing GlobalSelect for consistency
const Select = styled(GlobalSelect)``;

const DangerButton = styled(GlobalButton)`
  background-color: ${secondaryColor};

  &:hover {
    background-color: #D93025;
  }
`;

const Title = styled.h2`
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1.8rem;
  color: ${textColor};
  letter-spacing: 0;
  font-weight: 500;
  font-family: ${fontFamily};
`;

const SubTitle = styled.h4`
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: ${textColor};
  letter-spacing: 0;
  font-weight: 500;
  font-family: ${fontFamily};
`;

const BankrollContainer = styled.div`
  padding: 20px;
  background-color: ${backgroundColor};
  font-family: ${fontFamily};
  color: ${textColor};
  box-shadow: 0 4px 8px ${shadowColor};
  border-radius: 10px;
  transition: all 0.3s ease;
`;

const TableContainer = styled(Section)`
  margin-top: 10px;
  overflow-x: auto;
  max-height: 768px;
  overflow-y: auto;
  border-radius: 8px;
`;

const GlobalFilterInput = styled(GlobalInput)`
  margin-bottom: 15px;
`;

const ChartsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  padding: 15px 0;
  border-bottom: 1px solid ${borderColor};
  background-color: ${backgroundColor};
  border-radius: 8px;
  box-shadow: 0 4px 8px ${shadowColor};
`;

const ChartValue = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${primaryColor};
  text-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  font-family: ${fontFamily};
`;

const ChartLabel = styled.div`
  font-size: 0.9rem;
  color: #666;
  opacity: 0.7;
  font-family: ${fontFamily};
`;

const MetricDisplayContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  padding: 10px 0;
  border-bottom: 1px dashed ${borderColor};
`;

const MetricSection = styled.div`
  text-align: center;
`;

const MetricValue = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: ${textColor};
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  font-family: ${fontFamily};
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: ${textColor};
  font-size: 1rem;

  th,
  td {
    padding: 12px 10px;
    border-bottom: 1px solid ${borderColor};
    text-align: left;
    white-space: nowrap;
  }

  th {
    background-color: ${backgroundColor};
    color: ${textColor};
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    cursor: pointer;
    box-shadow: 0 2px 4px ${shadowColor};
  }

  tr:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const RunnerNameCell = styled.div`
  font-weight: bold;
  color: ${primaryColor};
  text-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
  font-family: ${fontFamily};
`;

const MeetingRaceCell = styled.div`
  color: ${secondaryColor};
  font-style: italic;
  text-shadow: 0 0 2px rgba(255, 204, 0, 0.5);
  font-family: ${fontFamily};
`;

const OddsCell = styled.div<{ isValueBet?: boolean }>`
  color: ${(props) => (props.isValueBet ? primaryColor : textColor)};
  font-weight: ${(props) => (props.isValueBet ? 'bold' : 'normal')};
  text-shadow: ${(props) => (props.isValueBet ? `0 0 3px rgba(0, 191, 255, 0.5)` : 'none')};
  font-family: ${fontFamily};
`;

const OverlayCell = styled.span<{ isPositive: boolean }>`
  color: ${(props) => (props.isPositive ? '#28a745' : secondaryColor)};
  font-weight: bold;
  font-family: ${fontFamily};
`;

const BetAmountCell = styled.div`
  font-weight: bold;
  color: ${primaryColor};
  text-shadow: 0 0 3px rgba(0, 191, 255, 0.5);
  font-family: ${fontFamily};
`;

const FormCommentCell = styled.div`
  font-style: italic;
  color: #666;
  opacity: 0.8;
  font-family: ${fontFamily};
`;

const ResultCell = styled.div<{ result?: number }>`
  color: ${(props) =>
    props.result === 1 ? '#28a745' : props.result === 2 ? '#ffc107' : textColor};
  font-weight: ${(props) => (props.result ? 'bold' : 'normal')};
  text-shadow: ${(props) =>
    props.result === 1
      ? `0 0 3px rgba(40, 167, 69, 0.5)`
      : props.result === 2
      ? `0 0 3px rgba(255, 193, 7, 0.5)`
      : 'none'};
  text-align: center;
  font-family: ${fontFamily};
`;

const BetStringTextarea = styled.textarea`
  width: 100%;
  height: 150px;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid ${borderColor};
  background-color: ${backgroundColor};
  color: ${textColor};
  font-size: 1rem;
  resize: vertical;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px ${shadowColor};
  font-family: ${fontFamily};

`;

const BankrollHeader = styled(GlobalTitle)`
  font-size: 2.5rem;
`;

const ActionButton = styled(GlobalButton)`
  padding: 12px 24px;
  font-size: 1.1rem;
  margin-bottom: 20px;
`;

interface WindowWrapperProps {
  x: number;
  y: number;
  width: number;
  height: number;
  zIndex: number;
  isFullscreen?: boolean;
}

const WindowWrapper = styled.div<WindowWrapperProps>`
  position: absolute;
  left: ${({ isFullscreen, x }) => (isFullscreen ? 0 : x)}px;
  top: ${({ isFullscreen, y }) => (isFullscreen ? 0 : y)}px;
  width: ${({ isFullscreen, width }) => (isFullscreen ? '100vw' : `${width}px`)};
  height: ${({ isFullscreen, height }) => (isFullscreen ? '100vh' : `${height}px`)};
  z-index: ${({ zIndex }) => zIndex};
  background: linear-gradient(135deg, rgba(0,255,255,0.8), rgba(255,20,147,0.8));
  backdrop-filter: blur(8px);
  box-shadow: 0 4px 8px ${shadowColor};
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  resize: both;
  border: 1px solid ${borderColor};
`;

const TitleBar = styled.div`
  display: flex;
  align-items: center;
  background: linear-gradient(135deg, rgba(36, 46, 46, 0.8), rgba(35, 32, 34, 0.8));
  backdrop-filter: blur(8px);
  padding: 8px 12px;
  cursor: move;
  box-shadow: 0 1px 3px ${shadowColor};
`;

const TitleText = styled.span`
  margin-right: 8px;
  color: ${textColor};
  font-weight: bold;
  font-family: ${fontFamily};
`;

const WindowButtonContainer = styled.div`
  margin-left: auto;
  display: flex;
  gap: 6px;
`;

const WindowButton = styled(GlobalButton)`
  padding: 4px 8px;
  font-size: 0.8rem;

  &:hover {
    background-color: ${backgroundColor};
  }
`;


const WindowContent = styled.div`
  flex: 1;
  overflow: auto;
  background: linear-gradient(135deg, rgba(169, 178, 178, 0.8), rgba(171, 12, 97, 0.8));
  backdrop-filter: blur(8px);
  color: ${textColor};
  padding: 6px;
`;

const TaskbarContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  padding: 0 8px;
  color: ${backgroundColor};
`;

const TaskbarItem = styled.div`
  background-color: rgba(255, 255, 255, 0.2);
  margin-right: 6px;
  padding: 0 6px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }

  & > span {
    margin-right: 4px;
  }
`;

const ChartSection = styled.div`
  padding: 10px;
  border: 1px solid ${borderColor};
  margin: 5px;
  border-radius: 5px;
  background-color: ${backgroundColor};
  box-shadow: 0 2px 4px ${shadowColor};
`;


const ChartTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${textColor};
  margin-bottom: 5px;
  text-align: center;
  font-family: ${fontFamily};
`;

const ResetButton = styled(GlobalButton)`
  background-color: ${secondaryColor};

  &:hover {
    background-color: #d32f2f;
  }

  
`;

const TableStyles = styled.div`
  padding: 0.3rem;
  overflow-x: auto;
  font-size: 0.85rem;
  font-family: ${fontFamily};
  table {
    border-spacing: 0;
    width: 100%;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
      cursor: pointer;
      &:hover {
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.3rem;
      :last-child {
        border-right: 0;
      }
    }
    th {
    }
  }
`;

const ChatContainer = styled.div`
  padding: 16px;
  color: ${textColor};
  background-color: ${backgroundColor};
`;

const ChatLog = styled.div`
  height: 200px;
  overflow-y: auto;
  border: 1px solid ${borderColor};
  padding: 8px;
  background-color: ${backgroundColor};
  color: ${textColor};
`;

const BankrollPromptContainer = styled.div`
  border: 1px solid ${primaryColor};
  padding: 16px;
  margin-top: 16px;
  background-color: ${backgroundColor};
`;

const InputField = styled(GlobalInput)`
  width: 80%;
  margin: 4px 0;
`;

const MainContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const Controls = styled.div`
  margin-bottom: 20px;
`;

const StyledButton = styled(GlobalButton)``;

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const MeetingGroup = styled.div`
  background-color: ${backgroundColor};
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px ${shadowColor};
`;

const RaceGroup = styled.div`
  margin-top: 10px;
`;

const RaceRunners = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const GridItem = styled.div`
  background-color: ${backgroundColor};
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;


  &.selected {
    border: 2px solid ${primaryColor};
  }
`;

const Gold = styled.div`
  background-color: green;
  color: ${backgroundColor};
  font-family: ${fontFamily};
`;

const Silver = styled.div`
  background-color: #c0c0c0;
  font-family: ${fontFamily};
`;

const Bronze = styled.div`
  background-color: #cd7f32;
  font-family: ${fontFamily};
`;

const RunnerInfo = styled.div`
  display: flex;
  align-items: center;
  font-family: ${fontFamily};
`;

const RunnerDetails = styled.div`
  background-color: ${backgroundColor};
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 3px ${shadowColor};

  span {
    display: block;
    margin-bottom: 5px;
    color: #555;
  }
`;

const Resizable = styled.div`
  resize: both;
  overflow: auto;
`;

const DesktopContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(135deg, rgba(0,255,255,0.8), rgba(255,20,147,0.8));
  backdrop-filter: blur(8px);
  background-size: 200% 200%;
  animation: ${gradientAnimation} 60s ease infinite;
  display: flex;
  justify-content: center;
  align-items: center;

`;

const IconContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: black;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const DesktopIcon = styled.div`
  width: 80px;
  height: 80px;
  margin: 10px;
  padding: 10px;
  font-size: 24px;
  text-align: center;
  border: 0px solid rgba(255,255,255,0.3);
  background: transparent;
  box-shadow: 0 1px 3px rgba(223, 221, 221, 0.5);
  border-radius: 8px;
  background-size: 200% 200%;
  color: black;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const IconLabel = styled.div`
  font-size: 13px;
  margin-top: 4px;
  color: ${backgroundColor};  
  font-family: ${fontFamily};
  &:hover {
    transform: scale(1.05);
  }
  color: black;
`;

const AddButton = styled(GlobalButton)`
  margin-top: 5px;
`;

const RemoveButton = styled(GlobalButton)`
  float: right;
  margin-left: 2.5px;
  padding: 3px 5px;
  font-size: 0.45em;
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.5);
  transition: background-color 0.2s, box-shadow 0.2s;
  &:hover {
    background-color: #e57373;
    box-shadow: 0 2px 4px rgba(0,0,0,0.6);
  }
`;

const ClearButton = styled(GlobalButton)`
  background-color: #ffc107;
  color: ${textColor};
  &:hover {
    background-color: #ffd740;
  }
`;

const RunAllButton = styled(GlobalButton)`
  &:hover:enabled {
    background-color: #64b5f6;
  }
`;

const FilterRule = styled.div`
  margin-bottom: 7.5px;
  border: 1px solid ${borderColor};
  padding: 7.5px;
  border-radius: 3px;
  background-color: ${backgroundColor};
  box-shadow: 0 1px 3px rgba(0,0,0,0.5);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px 15px;
`;

const ActionRule = styled(FilterRule)``;

const StyledAppContainer = styled.div`
  max-width: 490px;
  margin: 10px auto;
  padding: 12.5px;
  background: ${backgroundColor};
  border-radius: 4px;
  box-shadow: 0 2px 8px ${shadowColor};
  color: ${textColor};
  font-family: ${fontFamily};
  font-size: 8px;
  transition: all 0.3s ease-in-out;
  border: 1px solid ${borderColor};
`;

const TabNavigation = styled.div`
  display: flex;
  margin-bottom: 7.5px;
  border-bottom: 1px solid ${borderColor};
  background: ${backgroundColor};
`;

const TabButton = styled(GlobalButton)<{ active: boolean }>`
  background: ${(props) => (props.active ? `linear-gradient(135deg, ${primaryColor}, ${primaryColor})` : backgroundColor)};
  color: ${(props) => (props.active ? `${backgroundColor}` : `${textColor}`)};
  border: none;
  border-radius: 2px 2px 0 0;
  margin-right: 1px;
  padding: 5px 9px;
  cursor: pointer;
  box-shadow: ${(props) => (props.active ? '0 1px 3px rgba(0, 0, 0, 0.3)' : 'none')};
  transition: background-color 0.2s, box-shadow 0.2s;
  &:hover {
    background: linear-gradient(135deg, ${primaryColor}, ${primaryColor});
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  }
`;

const WizardContainer = styled.div`
  background: ${backgroundColor};
  border-radius: 4px;
  box-shadow: 0 2px 8px ${shadowColor};
  color: ${textColor};
  padding: 10px;
  transition: all 0.3s ease-in-out;
  border: 1px solid ${borderColor};
`;

const DashboardContainer = styled.div`
  padding: 10px;
  background: ${backgroundColor};
  border-radius: 4px;
  box-shadow: 0 2px 8px ${shadowColor};
  color: ${textColor};
  transition: all 0.3s ease-in-out;
  border: 1px solid ${borderColor};
`;

const SetupSection = styled.div`
  margin-bottom: 10px;
  border-bottom: 1px dashed ${borderColor};
  padding-bottom: 10px;
  background-color: ${backgroundColor};
  border-radius: 2px;
  padding: 7.5px;
`;

const SetupRow = styled.div`
  margin-bottom: 5px;
`;

const DateSelectionSection = styled.div`
  margin-top: 15px;
`;

const DateButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
`;

const DateButton = styled(GlobalButton)<{ selected: boolean }>`
  border: 1px solid ${borderColor};
  padding: 5px 7px;
  cursor: pointer;
  background: ${(props) => (props.selected ? primaryColor : backgroundColor)};
  color: ${(props) => (props.selected ? backgroundColor : textColor)};
  border-radius: 2.5px;
  box-shadow: ${(props) =>
    props.selected ? '0 1px 3px rgba(0, 0, 0, 0.3)' : 'none'};
  transition: background-color 0.2s, box-shadow 0.2s;
  &:hover {
    background: ${primaryColor};
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  }
`;

const WorkflowDesignerSection = styled.div``;

const FilterGroupTypeSelector = styled.div`
  margin-bottom: 15px;
`;

const ActionsSection = styled.div`
  margin-top: 15px;
`;

const WorkflowControlSection = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 7.5px;
  align-items: center;
  border-top: 1px dashed ${borderColor};
  padding-top: 7.5px;
  background-color: ${backgroundColor};
  padding: 7.5px;
  border-radius: 2px;
`;

const WorkflowDisplay = styled.div`
  margin-top: 10px;
  padding: 6px;
  border: 1px solid ${borderColor};
  border-radius: 3px;
  background-color: ${backgroundColor};
  overflow-x: auto;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
`;

const WorkflowTitle = styled.h4`
  margin-top: 0;
  color: ${textColor};
  font-family: ${fontFamily};
`;

const WorkflowJSON = styled.div`
  font-size: 0.45em;
  white-space: pre-wrap;
  background-color: ${backgroundColor};
  padding: 5px;
  border-radius: 2px;
  border: 1px solid ${borderColor};
  margin-top: 4px;
  color: ${textColor};
  font-family: ${fontFamily};
`;

const Message = styled.div`
  color: ${textColor};
  margin-bottom: 6px;
`;

const GlobalMessage = styled(Message)`
  color: ${textColor};
  margin-bottom: 6px;
`;

const ResultsContainer = styled.div`
  margin-top: 10px;
  border: 1px solid ${borderColor};
  padding: 7.5px;
  border-radius: 4px;
  background-color: ${backgroundColor};
`;

const RaceResult = styled.div`
  margin-bottom: 7.5px;
  padding: 6px;
  border: 1px solid ${borderColor};
  border-radius: 3px;
  background-color: ${backgroundColor};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  color: ${textColor};
`;

const RunnerResult = styled.div<{ excluded?: boolean }>`
  margin-bottom: 5px;
  padding-bottom: 4px;
  border-bottom: 1px dashed ${borderColor};
  color: ${(props) => (props.excluded ? secondaryColor : primaryColor)};
  display: flex;
  align-items: center;
  font-family: ${fontFamily};
`;

const RunnerSilk = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border-radius: 50%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
`;

const RunnerMetrics = styled.div`
  font-size: 0.45em;
  color: ${textColor};
  font-family: ${fontFamily};
`;

const ExcludedIndicator = styled.div`
  color: ${secondaryColor};
  font-weight: 500;
  margin-left: 10px;
  font-family: ${fontFamily};
`;

const IncludedIndicator = styled.div`
  color: ${primaryColor};
  font-weight: 500;
  margin-left: 10px;
  font-family: ${fontFamily};
`;

const ProcessingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 18, 18, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: ${backgroundColor};
`;

const ProcessingMessage = styled.h3`
  margin-bottom: 10px;
  font-weight: 500;
  color: ${backgroundColor};
  font-family: ${fontFamily};
`;

// --- Form Elements ---
const StyledLabel = styled.label`
  margin-right: 4px;
  font-weight: 500;
  color: ${textColor};
  font-size: 8px;
  font-family: ${fontFamily};
`;

const StyledSelect = styled(GlobalSelect)`
  margin-right: 5px;
  font-size: 8px;
`;

const StyledInput = styled(GlobalInput)`
  margin-right: 5px;
  font-size: 8px;
`;



// Neon Glow & Shimmer Animations
const neonGlow = keyframes`
  0% { box-shadow: 0 0 5px ${neonBlue}, 0 0 10px ${neonBlue}; }
  50% { box-shadow: 0 0 15px ${neonBlue}, 0 0 25px ${neonBlue}; }
  100% { box-shadow: 0 0 5px ${neonBlue}, 0 0 10px ${neonBlue}; }
`;

const shimmer = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const StyledProgress = styled.progress`
  width: 25%;
  height: 4px;
  appearance: none;
  &::-webkit-progress-bar {
    background-color: #333;
    border-radius: 4px;
  }
  &::-webkit-progress-value {
    background-color: ${primaryColor};
    border-radius: 4px;
  }
  &::-moz-progress-bar {
    background-color: ${primaryColor};
    border-radius: 4px;
  }
`;

export {
  UserInfoContainer,
  UserName,
  Button,
  LoginContainer,
  LoginButton,
  Container,
  Section,
  DashedSection,
  Pill,
  Input,
  Select,
  DangerButton,
  Title,
  SubTitle,
  backgroundColor,
  textColor,
  borderColor,
  shadowColor,
  subtleShimmer,
  BankrollContainer,
  BankrollHeader,
  ActionButton,
  TableContainer,
  GlobalFilterInput,
  ChartsContainer,
  ChartValue,
  ChartLabel,
  MetricDisplayContainer,
  MetricSection,
  MetricValue,
  StyledTable,
  RunnerNameCell,
  MeetingRaceCell,
  OddsCell,
  OverlayCell,
  BetAmountCell,
  FormCommentCell,
  ResultCell,
  BetStringTextarea,
  GlobalButton,
  GlobalTitle,
  GlobalSubTitle,
  WindowWrapper,
  TitleBar,
  TitleText,
  WindowButtonContainer,
  WindowButton,
  WindowContent,
  TaskbarContainer,
  TaskbarItem,
  ChartSection,
  ChartTitle,
  ResetButton,
  TableStyles,
  ChatContainer,
  ChatLog,
  BankrollPromptContainer,
  InputField,
  MainContent,
  Controls,
  StyledButton,
  GridContainer,
  MeetingGroup,
  RaceGroup,
  RaceRunners,
  GridItem,
  Gold,
  Silver,
  Bronze,
  RunnerInfo,
  RunnerDetails,
  Resizable,
  primaryColor,
  secondaryColor,
  fontFamily,
  DesktopContainer,
  IconContainer,
  DesktopIcon,
  IconLabel,
  gradientAnimation,
  AddButton,
  RemoveButton,
  ClearButton,
  RunAllButton,
  StyledLabel,
  StyledSelect,
  StyledInput,
  FilterRule,
  ActionRule,
  StyledAppContainer,
  TabNavigation,
  TabButton,
  WizardContainer,
  DashboardContainer,
  SetupSection,
  SetupRow,
  DateSelectionSection,
  DateButtonContainer,
  DateButton,
  WorkflowDesignerSection,
  FilterGroupTypeSelector,
  ActionsSection,
  WorkflowControlSection,
  WorkflowDisplay,
  WorkflowTitle,
  WorkflowJSON,
  ResultsContainer,
  RaceResult,
  RunnerResult,
  RunnerSilk,
  RunnerMetrics,
  ExcludedIndicator,
  IncludedIndicator,
  ProcessingOverlay,
  ProcessingMessage,
  StyledProgress,
  neonGlow,
  shimmer
};