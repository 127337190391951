import React, { createContext, useContext, useState, useRef, ReactNode, MutableRefObject } from 'react';

// Define the shape of the context state
interface WindowContextType {
    programs: any[];
    processes: any[];
    setProcesses: any;
    windows: any[];
    setWindows: any;
    topZ: any;
    pidCounter: any;
    winCounter: any;
    sendMessageToProgram: (programId: string, message: any) => void;
}

// Create the context with a default value
const WindowContext = createContext<WindowContextType | undefined>(undefined);

// Create a provider component
export const WindowProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [programs, setPrograms] = useState<any[]>([]);
    const [processes, setProcesses] = useState<any[]>([]);
    const [windows, setWindows] = useState<any[]>([]);
    const topZ = useRef<number>(0);
    const pidCounter = useRef<number>(0);
    const winCounter = useRef<number>(0);

    const sendMessageToProgram = (programId: string, message: any) => {
        setWindows((prev) =>
            prev.map((w) => {
                if (w.programId === programId) {
                    // Assuming each window component can handle a 'message' prop
                    return { ...w, component: React.cloneElement(w.component, { data: message }) };
                }
                return w;
            })
        );
    };

    const value = {
        programs,
        processes,
        setProcesses,
        windows,
        setWindows,
        topZ,
        pidCounter,
        winCounter,
        sendMessageToProgram,
    };

    return <WindowContext.Provider value={value}>{children}</WindowContext.Provider>;
};

// Custom hook to use the WindowContext
export const useWindowContext = (): WindowContextType => {
    const context = useContext(WindowContext);
    if (!context) {
        throw new Error('useWindowContext must be used within a WindowProvider');
    }
    return context;
};
