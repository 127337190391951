/*************************************************************************************************
 * taby_wizard_no_classic.tsx
 *
 * A TabyWizard component that uses advanced flat filter groups (instead of strictly nested
 * groups) and enhanced meeting selection/filtering. This version supports full AI Agent
 * integration via legacy methods (e.g., setRootGroup) and is styled to fit a 1024×768 window.
 *
 * The current bankroll (supplied via props.bankrollId) is used to fetch race calculations.
 *************************************************************************************************/

import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';
import styled, { keyframes } from 'styled-components';
import { Column, useTable } from 'react-table';
import {
  FilterGroup,
  CustomVariable,
  WorkflowDefinition,
  ExtendedActionType,
  FilterEvaluator,
  WorkflowEngine,
  generateUUID,
} from '../agents/operator_taby';
import NestedGroupEditor from '../NestedGroupEditor';
import { apiService } from './types';
import eventEmitter from '../../utils/EventEmitter';
import { TableStyles, Container, Section, Pill, Input, Select, Button, DashedSection, DangerButton, GlobalTitle, GlobalSubTitle } from '../../globalStyles';

/* ------------------------------ React Table for Meetings ------------------------------ */
interface Meeting {
  venueMnemonic: string;
  status?: string;
  races: any[];
  meetingDate?: string;
}

interface MeetingTableProps {
  data: Meeting[];
  selectedVenues: string[];
  onToggleVenue: (venue: string, selected: boolean) => void;
}



const MeetingTable: React.FC<MeetingTableProps> = ({ data, selectedVenues, onToggleVenue }) => {
  const columns: Column<Meeting>[] = React.useMemo(
    () => [
      {
        Header: '',
        id: 'selection',
        Cell: ({ row }: any) => {
          const venue = row.original.venueMnemonic;
          const isChecked = selectedVenues.includes(venue);
          return (
            <input
              type="checkbox"
              style={{ margin: 0 }} /* Remove extra margin from checkbox */
              checked={isChecked}
              onChange={(e) => onToggleVenue(venue, e.target.checked)}
            />
          );
        },
      },
      {
        Header: 'Venue',
        accessor: 'venueMnemonic',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Races',
        accessor: (row) => row.races.length,
        id: 'races',
      },
      {
        Header: 'Date',
        accessor: 'meetingDate',
      },
    ],
    [selectedVenues, onToggleVenue]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <TableStyles>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => {
                  const venue = row.original.venueMnemonic;
                  const isChecked = selectedVenues.includes(venue);
                  onToggleVenue(venue, !isChecked);
                }}
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </TableStyles>
  );
};

/* ------------------------------ Helper Functions ------------------------------ */
const availableProperties = [
  'runnerName',
  'runnerNumber',
  'currentOdds',
  'averagePace',
  'runnerScore',
  'probabilityScore',
  'meetingName',
  'raceNumber',
];

function combineGroups(groups: FilterGroup[]): FilterGroup {
  return {
    groupType: 'AND',
    conditions: groups,
  };
}

function createWorkflowFromUI(
  groups: FilterGroup[],
  actions: ActionForm[],
  customVariables: CustomVariable[],
  failActions: ActionForm[]
): WorkflowDefinition | null {
  const combinedGroup = combineGroups(groups);
  if (!combinedGroup || !combinedGroup.conditions || combinedGroup.conditions.length === 0) {
    return null;
  }
  const workflow: WorkflowDefinition = {
    workflowName: 'Workflow from Flat Groups',
    customVariables,
    rules: [
      {
        id: generateUUID(),
        filter: {
          name: 'Flat Group Filter',
          rootGroup: combinedGroup,
        },
        onSuccessAction: actions.map((a) => ({
          actionType: a.actionType as ExtendedActionType,
          config: a.config,
        })),
        onFailAction:
          failActions.length > 0
            ? failActions.map((a) => ({
                actionType: a.actionType as ExtendedActionType,
                config: a.config
              }))
            : [
                {
                  actionType: 'SKIP_RUNNER',
                  config: { message: 'Runner failed the flat filter group conditions.' },
                },
              ],
      },
    ],
  };
  return workflow;
}

/* ------------------------------ Main Component: TabyWizard ------------------------------ */

export interface ActionForm {
  actionType: string;
  config: any;
}

export interface TabyWizardRef {
  getGroups: () => FilterGroup[];
  setGroups: (groups: FilterGroup[]) => void;
  getActions: () => ActionForm[];
  setActions: (newActions: ActionForm[]) => void;
  getFailActions: () => ActionForm[];
  setFailActions: (newFailActions: ActionForm[]) => void;
  getCustomVars: () => CustomVariable[];
  setCustomVars: (newVars: CustomVariable[]) => void;
  runProcessAll: () => Promise<void>;
  getResults: () => any[];
  selectAllMeetings: () => void;
  setDate: (date: string) => void;
  getProgress: () => number;
  cancelProcessing: () => void;
  setRootGroup: (group: FilterGroup) => void;
}

interface TabyWizardProps {
  jurisdiction: string;
  raceType: string;
  bankrollId: string;
}

const encodeToBase64 = (data: any) => {
  return btoa(JSON.stringify(data));
};

const decodeFromBase64 = (base64: string) => {
  return JSON.parse(atob(base64));
};

export const Taby = forwardRef<TabyWizardRef, TabyWizardProps>(function TabyWizardInternal(
  { jurisdiction, raceType, bankrollId },
  ref
) {
  // Meeting and processing state
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [meetingDates, setMeetingDates] = useState<string[]>([]);
  const [allMeetings, setAllMeetings] = useState<any[]>([]);
  const [availableMeetings, setAvailableMeetings] = useState<any[]>([]);
  const [selectedVenues, setSelectedVenues] = useState<string[]>([]);
  const [meetingFilter, setMeetingFilter] = useState<string>('');
  const [meetingSort, setMeetingSort] = useState<string>('');
  const [step4Races, setStep4Races] = useState<any[]>([]);
  const [processing, setProcessing] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [message, setMessage] = useState<string | null>(null);

  // Group, action, and variable state
  const [groups, setGroups] = useState<FilterGroup[]>([{ groupType: 'AND', conditions: [] }]);
  const [actions, setActionsState] = useState<ActionForm[]>([]);
  const [failActions, setFailActionsState] = useState<ActionForm[]>([]);
  const [customVariables, setCustomVariablesState] = useState<CustomVariable[]>([]);

  const cancelProcessingRef = useRef<boolean>(false);

  const [base64String, setBase64String] = useState<string>('');

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  /* -------------------- Setup Meeting Dates -------------------- */
  useEffect(() => {
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0];
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const formattedTomorrow = tomorrow.toISOString().split('T')[0];
    const dayAfterTomorrow = new Date(today);
    dayAfterTomorrow.setDate(today.getDate() + 2);
    const formattedDayAfterTomorrow = dayAfterTomorrow.toISOString().split('T')[0];
    setMeetingDates([formattedToday, formattedTomorrow, formattedDayAfterTomorrow]);
  }, []);

  /* -------------------- Fetch Meetings -------------------- */
  useEffect(() => {
    if (!selectedDate) {
      setAllMeetings([]);
      setAvailableMeetings([]);
      setSelectedVenues([]);
      return;
    }
    const fetchMeetings = async () => {
      try {
        const fetched = await apiService.fetchMeetingsByType(selectedDate, raceType, jurisdiction);
        if (fetched) {
          setAllMeetings(fetched);
          const filtered = fetched.filter(
            (m: any) => m.raceType === 'R' && m.races && m.races.length > 0 && m.sellCode !== null
          );
          setAvailableMeetings(filtered);
          setSelectedVenues([]); // reset selected venues on new fetch
        }
      } catch (err) {
        console.error('Error fetching meetings', err);
        setAllMeetings([]);
        setAvailableMeetings([]);
        setSelectedVenues([]);
      }
    };
    fetchMeetings();
  }, [selectedDate, raceType, jurisdiction]);

  /* -------------------- Meeting Filtering -------------------- */
  const getDisplayedMeetings = () => {
    let meetings = [...availableMeetings];
    if (meetingFilter.trim() !== '') {
      meetings = meetings.filter((m) =>
        m.venueMnemonic.toLowerCase().includes(meetingFilter.toLowerCase())
      );
    }
    return meetings;
  };

  /* -------------------- Process Meetings -------------------- */
  const processAll = async () => {
    if (!selectedDate) {
      setMessage('Please select a date.');
      return;
    }
    if (availableMeetings.length === 0) {
      setMessage('No meetings available for the selected date.');
      return;
    }
    if (selectedVenues.length === 0) {
      setMessage('Please select at least one venue.');
      return;
    }

    setProcessing(true);
    setProgress(0);
    setStep4Races([]);
    setMessage(null);
    cancelProcessingRef.current = false;

    try {
      const meetingsToProcess = availableMeetings.filter((m) =>
        selectedVenues.includes(m.venueMnemonic)
      );
      if (meetingsToProcess.length === 0) {
        setProcessing(false);
        return;
      }

      const allRaces: any[] = [];
      let totalRaces = 0;
      meetingsToProcess.forEach((meeting) => {
        totalRaces += meeting.races.length;
      });
      let processedRaces = 0;

      for (const meeting of meetingsToProcess) {
        if (cancelProcessingRef.current) break;
        for (let raceIdx = 0; raceIdx < meeting.races.length; raceIdx++) {
          if (cancelProcessingRef.current) break;
          const raceNumber = meeting.races[raceIdx].raceNumber;
          try {
            const data = await apiService.getCalculations(
              jurisdiction,
              meeting.meetingDate || selectedDate,
              meeting.venueMnemonic,
              meeting.raceType || raceType,
              raceNumber,
              bankrollId
            );
            const totalRunners = data.runners ? data.runners.length : 0;
            const totalPace = data.runners.reduce(
              (sum: number, runner: any) => sum + (runner.averagePace || 0),
              0
            );
            const averagePace = totalRunners > 0 ? totalPace / totalRunners : 0;

            const enhancedData = {
              ...data,
              ...meeting,
              totalRunners,
              averagePace,
              runners: data.runners.map((r: any) => {
                const fairOdds = 1 / r.probabilityScore;
                const overlay = fairOdds - r.currentOdds;
                return {
                  ...r,
                  meetingName: meeting.meetingName,
                  raceNumber,
                  sellCode: {
                    meetingCode: meeting.sellCode.meetingCode,
                    scheduledType: meeting.sellCode.scheduledType,
                  },
                  fairOdds,
                  overlay,
                  overlayPercent: overlay / r.currentOdds,
                  underlay: r.currentOdds - fairOdds,
                  underlayPercent: (r.currentOdds - fairOdds) / r.currentOdds,
                  isValueBet: overlay > 0,
                  isPaceGood: r.averagePace >= averagePace,
                };
              }),
            };

            const workflowToUse = createWorkflowFromUI(
              groups,
              actions,
              customVariables,
              failActions
            );
            if (!workflowToUse) {
              console.warn('No flat groups defined; skipping workflow logic.');
              allRaces.push({
                date: selectedDate,
                jurisdiction,
                venueMnemonic: meeting.venueMnemonic,
                raceNumber,
                runners: enhancedData.runners || [],
              });
            } else {
              const engine = new WorkflowEngine(new FilterEvaluator());
              if (enhancedData && enhancedData.runners) {
                const processedRunners = enhancedData.runners.map((runner: any) => {
                  const pass = engine.run(
                    workflowToUse,
                    runner,
                    workflowToUse.customVariables || []
                  );
                  if (pass) {
                    eventEmitter.emit('PLACE_BET', runner, {});
                    eventEmitter.emit('dataProcessed', runner, meeting);
                  }
                  return { ...runner, excluded: !pass };
                });
                allRaces.push({
                  date: selectedDate,
                  jurisdiction,
                  venueMnemonic: meeting.venueMnemonic,
                  raceNumber,
                  runners: processedRunners,
                });
              } else {
                console.warn(`No runner data for ${meeting.venueMnemonic} Race ${raceNumber}.`);
                allRaces.push({
                  date: selectedDate,
                  jurisdiction,
                  venueMnemonic: meeting.venueMnemonic,
                  raceNumber,
                  runners: [],
                });
              }
            }
          } catch (err) {
            console.error(`Error processing ${meeting.venueMnemonic} Race ${raceNumber}:`, err);
          }
          processedRaces++;
          setProgress((processedRaces / totalRaces) * 100);
        }
      }
      setStep4Races(allRaces);
      console.log('Race processing complete.');
    } catch (err) {
      console.error('Error processing selected meetings:', err);
      alert('Error during meeting processing. Check console for details.');
    } finally {
      setProcessing(false);
    }
  };

  /* -------------------- Meeting Selection Helpers -------------------- */
  const selectAllMeetings = () => {
    setSelectedVenues(availableMeetings.map((m) => m.venueMnemonic));
  };

  /* -------------------- Group Management -------------------- */
  const addGroup = () => {
    setGroups([...groups, { groupType: 'AND', conditions: [] }]);
  };

  const removeGroup = (index: number) => {
    setGroups(groups.filter((_, idx) => idx !== index));
  };

  /* -------------------- Expose Legacy API via ref -------------------- */
  useImperativeHandle(ref, () => ({
    getGroups: () => groups,
    setGroups: (newGroups: FilterGroup[]) => setGroups(newGroups),
    getActions: () => actions,
    setActions: (newActions: ActionForm[]) => setActionsState(newActions),
    getFailActions: () => failActions,
    setFailActions: (newFailActions: ActionForm[]) => setFailActionsState(newFailActions),
    getCustomVars: () => customVariables,
    setCustomVars: (newVars: CustomVariable[]) => setCustomVariablesState(newVars),
    runProcessAll: async () => {
      await processAll();
    },
    getResults: () => step4Races,
    selectAllMeetings,
    setDate: (date: string) => setSelectedDate(date),
    getProgress: () => progress,
    cancelProcessing: () => {
      cancelProcessingRef.current = true;
    },
    setRootGroup: (newRootGroup: FilterGroup) => {
      if (groups.length > 0) {
        const newGroups = [...groups];
        newGroups[0] = newRootGroup;
        setGroups(newGroups);
      } else {
        setGroups([newRootGroup]);
      }
    },
  }));

  /* -------------------- Compute Selected Runners (for external use) -------------------- */
  const [selectedRunners, setSelectedRunners] = useState<any[]>([]);
  useEffect(() => {
    const runners = step4Races.flatMap((race) =>
      race.runners
        .filter((r: any) => !r.excluded)
        .map((r: any) => ({
          ...r,
          meetingName: race.venueMnemonic,
          meetingDate: race.date,
          raceNumber: race.raceNumber,
        }))
    );
    setSelectedRunners(runners);
  }, [step4Races]);

  useEffect(() => {
    const storedBankroll = localStorage.getItem('bankroll');
    if (!storedBankroll) {
      setIsModalOpen(true);
    }
  }, []);


  const resetData = () => {
    setGroups([{ groupType: 'AND', conditions: [] }]);
    setActionsState([]);
    setFailActionsState([]);
    setCustomVariablesState([]);
    setStep4Races([]);
    setSelectedDate('');
    setAvailableMeetings([]);
    setSelectedVenues([]);
    setMessage(null);
    setProgress(0);
  };


  return (  
    <Container>
      <GlobalTitle>TabyWizard (Flat Groups & Enhanced Meetings)</GlobalTitle>
      {message && <div>{message}</div>}

      <Section>
        <GlobalSubTitle>Date & Meetings</GlobalSubTitle>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
          <div style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>Select Date:</div>
          <div>
            {meetingDates.map((d) => (
              <Pill key={d} selected={selectedDate === d} onClick={() => setSelectedDate(d)}>
                {d}
              </Pill>
            ))}
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '6px', gap: '6px' }}>
          <Input
            type="text"
            placeholder="Filter by meeting name"
            value={meetingFilter}
            onChange={(e) => setMeetingFilter(e.target.value)}
            style={{ flex: 1 }}
          />
          <Select
            value={meetingSort}
            onChange={(e) => setMeetingSort(e.target.value)}
            style={{ width: 'auto' }}
          >
            <option value="">Sort by Status</option>
            <option value="status">Status</option>
          </Select>
          <Button onClick={selectAllMeetings} style={{ whiteSpace: 'nowrap' }}>Select All</Button>
        </div>
        <MeetingTable
          data={getDisplayedMeetings()}
          selectedVenues={selectedVenues}
          onToggleVenue={(venue, isSelected) => {
            setSelectedVenues((prev) => {
              if (isSelected) {
                return [...prev, venue];
              } else {
                return prev.filter(v => v !== venue);
              }
            });
          }}
        />
      </Section>

      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
        <Section style={{ flex: 2, minWidth: '300px' }}>
          <GlobalSubTitle>Flat Filter Groups</GlobalSubTitle>
          {groups.map((group, index) => (
            <Section key={index} style={{ marginBottom: '8px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '4px' }}>
                <strong style={{ fontSize: '0.9rem' }}>Group {index + 1}</strong>
                <Button onClick={() => removeGroup(index)} style={{ backgroundColor: '#dc3545', padding: '4px 8px', fontSize: '0.8rem' }}>
                  Remove
                </Button>
              </div>
              <NestedGroupEditor
                group={group}
                onChange={(g: FilterGroup) => {
                  const newGroups = [...groups];
                  newGroups[index] = g;
                  setGroups(newGroups);
                }}
                customVariableNames={customVariables.map((v) => v.name)}
              />
            </Section>
          ))}
          <Button onClick={addGroup}>+ Add Group</Button>
        </Section>

        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '10px', minWidth: '250px' }}>
          <DashedSection>
            <GlobalSubTitle>Actions (Pass)</GlobalSubTitle>
            {actions.map((action, index) => (
              <div key={index} style={{ marginBottom: '4px', display: 'flex', alignItems: 'center' }}>
                <Button onClick={() => {
                  const copy = [...actions];
                  copy.splice(index, 1);
                  setActionsState(copy);
                }} style={{ backgroundColor: '#dc3545', padding: '2px 6px', fontSize: '0.7rem' }}>
                  X
                </Button>
                <Select
                  value={action.actionType}
                  onChange={(e) => {
                    const copy = [...actions];
                    copy[index] = { ...copy[index], actionType: e.target.value };
                    setActionsState(copy);
                  }}
                  style={{ width: 'auto', flex: '1', marginRight: '4px' }}
                >
                  <option value="NOP">NOP</option>
                  <option value="PLACE_BET">PLACE_BET</option>
                  <option value="LOG_MESSAGE">LOG_MESSAGE</option>
                  <option value="SEND_WINDOW_MESSAGE">SEND_WINDOW_MESSAGE</option>
                  <option value="OPEN_PROGRAM">OPEN_PROGRAM</option>
                  <option value="CALL_API">CALL_API</option>
                  <option value="CREATE_PROMPT">CREATE_PROMPT</option>
                  <option value="CALL_AGENT">CALL_AGENT</option>
                  <option value="GENERATE_BET_STRING">GENERATE_BET_STRING</option>
                  <option value="SKIP_RUNNER">SKIP_RUNNER</option>
                  <option value="SKIP_RACE">SKIP_RACE</option>
                  <option value="SKIP_MEETING">SKIP_MEETING</option>
                  <option value="SEND_TO_OPERATOR">SEND_TO_OPERATOR</option>
                </Select>
                <Input
                  type="text"
                  placeholder="Config"
                  value={typeof action.config === 'string' ? action.config : JSON.stringify(action.config)}
                  onChange={(e) => {
                    let val: any = e.target.value;
                    try {
                      val = JSON.parse(e.target.value);
                    } catch {}
                    const copy = [...actions];
                    copy[index] = { ...copy[index], config: val };
                    setActionsState(copy);
                  }}
                  style={{ width: 'auto', flex: '2', minWidth: '100px' }}
                />
              </div>
            ))}
            <Button onClick={() => setActionsState([...actions, { actionType: 'NOP', config: {} }])}>
              + Add Action
            </Button>
          </DashedSection>

          <DashedSection>
            <GlobalSubTitle>Actions (Fail)</GlobalSubTitle>
            {failActions.map((action, index) => (
              <div key={index} style={{ marginBottom: '4px', display: 'flex', alignItems: 'center' }}>
                <Button onClick={() => {
                  const copy = [...failActions];
                  copy.splice(index, 1);
                  setFailActionsState(copy);
                }} style={{ backgroundColor: '#dc3545', padding: '2px 6px', fontSize: '0.7rem' }}>
                  X
                </Button>
                <Select
                  value={action.actionType}
                  onChange={(e) => {
                    const copy = [...failActions];
                    copy[index] = { ...copy[index], actionType: e.target.value };
                    setFailActionsState(copy);
                  }}
                  style={{ width: 'auto', flex: '1', marginRight: '4px' }}
                >
                  <option value="NOP">NOP</option>
                  <option value="PLACE_BET">PLACE_BET</option>
                  <option value="LOG_MESSAGE">LOG_MESSAGE</option>
                  <option value="SEND_WINDOW_MESSAGE">SEND_WINDOW_MESSAGE</option>
                  <option value="OPEN_PROGRAM">OPEN_PROGRAM</option>
                  <option value="CALL_API">CALL_API</option>
                  <option value="CREATE_PROMPT">CREATE_PROMPT</option>
                  <option value="CALL_AGENT">CALL_AGENT</option>
                  <option value="GENERATE_BET_STRING">GENERATE_BET_STRING</option>
                  <option value="SKIP_RUNNER">SKIP_RUNNER</option>
                  <option value="SKIP_RACE">SKIP_RACE</option>
                  <option value="SKIP_MEETING">SKIP_MEETING</option>
                  <option value="SEND_TO_OPERATOR">SEND_TO_OPERATOR</option>
                </Select>
                <Input
                  type="text"
                  placeholder="Config"
                  value={typeof action.config === 'string' ? action.config : JSON.stringify(action.config)}
                  onChange={(e) => {
                    let val: any = e.target.value;
                    try {
                      val = JSON.parse(e.target.value);
                    } catch {}
                    const copy = [...failActions];
                    copy[index] = { ...copy[index], config: val };
                    setFailActionsState(copy);
                  }}
                  style={{ width: 'auto', flex: '2', minWidth: '100px' }}
                />
              </div>
            ))}
            <Button onClick={() => setFailActionsState([...failActions, { actionType: 'NOP', config: {} }])}>
              + Add Fail Action
            </Button>
          </DashedSection>

          <DashedSection>
            <GlobalSubTitle>Custom Variables</GlobalSubTitle>
            {customVariables.map((cv, i) => (
              <div key={i} style={{ marginBottom: '4px', display: 'flex', alignItems: 'center' }}>
                <Button onClick={() => {
                  const cpy = [...customVariables];
                  cpy.splice(i, 1);
                  setCustomVariablesState(cpy);
                }} style={{ backgroundColor: '#dc3545', padding: '2px 6px', fontSize: '0.7rem' }}>
                  X
                </Button>
                <Input
                  type="text"
                  placeholder="Variable Name"
                  style={{ width: 'auto', flex: '1', marginRight: '4px' }}
                  value={cv.name}
                  onChange={(e) => {
                    const cpy = [...customVariables];
                    cpy[i] = { ...cpy[i], name: e.target.value };
                    setCustomVariablesState(cpy);
                  }}
                />
                <Select
                  value={cv.expression}
                  onChange={(e) => {
                    const cpy = [...customVariables];
                    cpy[i] = { ...cpy[i], expression: e.target.value };
                    setCustomVariablesState(cpy);
                  }}
                  style={{ width: 'auto', flex: '2' }}
                >
                  <option value="">Select Property</option>
                  {availableProperties.map((prop) => (
                    <option key={prop} value={prop}>
                      {prop}
                    </option>
                  ))}
                </Select>
              </div>
            ))}
            <Button onClick={() => setCustomVariablesState([...customVariables, { name: '', expression: '' }])}>
              + Add Variable
            </Button>
          </DashedSection>
        </div>
      </div>

      <Section>
        <GlobalSubTitle>Export/Import Rules & Filters</GlobalSubTitle>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Button onClick={() => {
            const base64 = encodeToBase64({ groups, actions, failActions, customVariables });
            setBase64String(base64);
          }}>
            Export to Base64
          </Button>
          <textarea
            value={base64String}
            onChange={(e) => setBase64String(e.target.value)}
            rows={4}
            style={{ width: '100%', fontSize: '0.9rem' }}
          />
          <Button onClick={() => {
            try {
              const importedData = decodeFromBase64(base64String);
              setGroups(importedData.groups || []);
              setActionsState(importedData.actions || []);
              setFailActionsState(importedData.failActions || []);
              setCustomVariablesState(importedData.customVariables || []);
            } catch (error) {
              console.error('Invalid Base64 input:', error);
            }
          }}>
            Import from Base64
          </Button>
        </div>
      </Section>

      {/* Fixed panel for process buttons - centered and pinned at the top of the parent container */}
      <div style={{
        position: 'absolute',  // Use absolute positioning relative to the parent container
        top: 0,                // Pin to the top of the parent container
        left: '50%',           // Center horizontally
        transform: 'translateX(-50%)', // Adjust for perfect horizontal centering
        width: '100%',         // Optionally, you may set a maxWidth if needed
        backgroundColor: '#f8f9fa',
        padding: '10px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
      }}>
        <Button
          onClick={processAll}
          disabled={!selectedDate || processing || availableMeetings.length === 0}
          style={{ padding: '12px 24px', fontSize: '1rem' }}
        >
          {processing ? 'Processing...' : 'Process Meetings'}
        </Button>
        <DangerButton
          onClick={() => {
            cancelProcessingRef.current = true;
          }}
          disabled={!processing}
          style={{ padding: '12px 24px', fontSize: '1rem' }}
        >
          Stop
        </DangerButton>
      </div>

      {processing && (
        <div style={{ marginTop: '6px', textAlign: 'right' }}>
          <div>Progress: {progress.toFixed(1)}%</div>
        </div>
      )}
    </Container>
  );
});