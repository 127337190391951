import React, { useState, useEffect } from 'react';

/**
 * DigitalClock Component
 * 
 * This component displays the current time in a digital format, updating every second.
 * It is designed to be styled and positioned to be an eye-catching feature on the desktop.
 */
const DigitalClock: React.FC = () => {
  // State to hold the current time
  const [time, setTime] = useState(new Date());

  // Effect to update the time every second
  useEffect(() => {
    const timerId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timerId);
  }, []);

  return (
    <div
      style={{
        position: 'absolute',
        top: '20%',
        right: '20px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: '10px',
        borderRadius: '5px',
        color: 'white',
        fontFamily: 'monospace',
        fontSize: '1.2em'
      }}
    >
      {time.toLocaleTimeString()}
    </div>
  );
};

export default DigitalClock; 