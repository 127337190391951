
const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
const OPENAI_ENDPOINT = "https://api.openai.com/v1/chat/completions";

/**
 * Queries the OpenAI API with a system message that sets up TABY's behavior and a user message
 * containing the current application state and prompt.
 * 
 * @param prompt - The user's message.
 * @param context - The current application state to be included in the conversation.
 * @returns A promise resolving to the AI's response as a string.
 */
export async function queryGPT(prompt: string, context: any): Promise<string> {
  // Define the system instruction that configures TABY's personality and logic.
  const systemMessage = `
You are TABY, an advanced horse racing AI assistant.
Your primary focus is to provide betting insights, bankroll management, and race predictions.

Logic:
- If the bankroll is missing, suggest initializing it.
- If no races have been fetched, fetch available races based on jurisdiction.
- If a race is selected but no predictions exist, retrieve predictions.
- If everything is set, provide betting recommendations.
  `.trim();

  // Prepare the user message by combining the current app state and the user's prompt.
  const userMessage = `
Current App State: ${JSON.stringify(context)}
User Message: ${prompt}
  `.trim();

  try {
    const response = await fetch(OPENAI_ENDPOINT, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${OPENAI_API_KEY}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        model: "gpt-4-turbo",
        messages: [
          { role: "system", content: systemMessage },
          { role: "user", content: userMessage }
        ],
      }),
    });

    if (!response.ok) {
      // Log detailed error information and throw an error to be caught below.
      const errorText = await response.text();
      console.error("OpenAI API Error:", errorText);
      throw new Error(`OpenAI API Error: ${errorText}`);
    }

    const data = await response.json();
    return data.choices[0]?.message?.content || "No response from AI.";
  } catch (error) {
    console.error("queryGPT encountered an error:", error);
    return "An unexpected error occurred while processing your request.";
  }
}

export const gpt = {
    queryGPT
}
