import React, { useState } from 'react';
import styled from 'styled-components';
import { ProgramDefinition } from './Windows/types';
import QuickLaunch from './QuickLaunch';
import DigitalClock from './DigitalClock';
import ThemeToggle from './ThemeToggle';

/**
 * StartMenuProps interface defines the properties for the StartMenu component.
 * @property programs - An array of available programs.
 * @property launchProgram - A function to launch a program by its ID.
 * @property theme - Current theme ('light' or 'dark').
 * @property toggleTheme - Function to toggle the theme.
 * @property onClose - Function to close/hide the Start Menu.
 */
interface StartMenuProps {
  programs: ProgramDefinition[];
  launchProgram: (programId: string) => void;
  theme: 'light' | 'dark';
  toggleTheme: () => void;
  onClose: () => void;
}

/**
 * The StartMenu component replicates a Windows 10 like Start Menu with a search bar,
 * pinned apps grid, and an all apps list. It also includes system controls such as a digital clock,
 * theme toggle, and a close button.
 */
const StartMenu: React.FC<StartMenuProps> = ({ programs, launchProgram, theme, toggleTheme, onClose }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  // Filter apps based on search term.
  const filteredApps = programs.filter(app =>
    app.displayName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // When no search term, separate pinned and non-pinned apps.
  const pinnedApps = programs.filter(app => app.pinned);
  const nonPinnedApps = programs.filter(app => !app.pinned).sort((a, b) => a.displayName.localeCompare(b.displayName));

  return (
    <StartMenuContainer themeMode={theme}>
      <SearchBar themeMode={theme}>
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Type to search apps..."
        />
      </SearchBar>
      {searchTerm ? (
        <>
          <SectionTitle>Pinned Apps - Search Results</SectionTitle>
          <PinnedGrid>
            {filteredApps.map(app => (
              <GridItem key={app.id} onClick={() => { launchProgram(app.id); onClose(); }}>
                <IconDisplay>{app.iconUrl}</IconDisplay>
                <AppLabel>{app.displayName}</AppLabel>
              </GridItem>
            ))}
          </PinnedGrid>
        </>
      ) : (
        <>
          <SectionTitle>Pinned</SectionTitle>
          <PinnedGrid>
            {pinnedApps.map(app => (
              <GridItem key={app.id} onClick={() => { launchProgram(app.id); onClose(); }}>
                <IconDisplay>{app.iconUrl}</IconDisplay>
                <AppLabel>{app.displayName}</AppLabel>
              </GridItem>
            ))}
          </PinnedGrid>
          <SectionTitle>All Apps</SectionTitle>
          <AppsList>
            {nonPinnedApps.map(app => (
              <AppListItem key={app.id} onClick={() => { launchProgram(app.id); onClose(); }}>
                <IconSmall>{app.iconUrl}</IconSmall>
                <AppLabel>{app.displayName}</AppLabel>
              </AppListItem>
            ))}
          </AppsList>
        </>
      )}
      <SystemSection themeMode={theme}>
        <DigitalClock />
        <ThemeToggle theme={theme} toggleTheme={toggleTheme} />
        <CloseButton themeMode={theme} onClick={onClose}>Close</CloseButton>
      </SystemSection>
    </StartMenuContainer>
  );
};

export default StartMenu;

// ==================== Styled Components ====================
const StartMenuContainer = styled.div<{ themeMode: 'light' | 'dark' }>`
  background: linear-gradient(135deg, rgba(0,255,255,0.8), rgba(255,20,147,0.8));
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255,20,147,0.5);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 30px rgba(0,255,255,0.7);
  color: #fff;
  width: 350px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
`;

const SearchBar = styled.div<{ themeMode: 'light' | 'dark' }>`
  padding: 10px;
  background-color: rgba(0,0,0,0.3);
  border-radius: 5px;
  margin-bottom: 10px;

  input {
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 4px;
    outline: none;
    background: rgba(0,0,0,0.2);
    color: #fff;
    box-shadow: 0 0 10px rgba(0,255,255,0.5);
  }
`;

const SectionTitle = styled.h4`
  margin: 10px 0 5px;
`;

const PinnedGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: 15px;
  margin-bottom: 20px;
`;

const GridItem = styled.div`
  cursor: pointer;
  text-align: center;
  padding: 10px;
  border-radius: 4px;
  &:hover {
    background-color: rgba(0,0,0,0.1);
  }
`;

const IconDisplay = styled.div`
  font-size: 48px;
  margin-bottom: 5px;
`;

const AppLabel = styled.div`
  font-size: 14px;
`;

const AppsList = styled.div`
  flex: 1;
  overflow-y: auto;
  margin-bottom: 20px;
`;

const AppListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background-color: rgba(0,0,0,0.1);
  }
`;

const IconSmall = styled.div`
  font-size: 32px;
  margin-right: 10px;
`;

const SystemSection = styled.div<{ themeMode: 'light' | 'dark' }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${props => props.themeMode === 'light' ? '#ccc' : '#444'};
  padding-top: 10px;
`;

const CloseButton = styled.button<{ themeMode: 'light' | 'dark' }>`
  background-color: rgba(255,20,147,0.8);
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(255,20,147,0.8);
  color: #fff;
`; 