import React from 'react';
import { TabyProvider } from '../../providers/tabyProvider';
import { TabyChat } from '../Taby/TabyChat';

export function AgentInterface() {
  return (
    <TabyProvider>
      <div style={{ padding: 20, border: "2px solid black", maxWidth: 600 }}>
        <h2>🔗 TABY AI Agent Interface</h2>
        <TabyChat />
      </div>
    </TabyProvider>
  );
}