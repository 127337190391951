import { Race } from "@/types/tab.types";
import axios, { AxiosInstance } from "axios";

export interface MeetingDate { meetingDate: string; }
export interface Meeting {
    sellCode: any; races: Race[]; meetingName: string; location: string; raceType: string; meetingDate: string; venueMnemonic: string; runners: Runner[]; 
}
export interface Runner { runnerName: string; runnerNumber: number; runnerScore: number; optimalRaceDistance: boolean; probabilityScore: number; currentOdds: number; stabilityScore: number; staminaFactor: number; averagePace: number; prediction: number; result: number; closingSpeedIndex: number; trackSuitability: number; energyEfficiency: number; preferredTrackSurface: boolean; competitionEnergy: number; blinkers: boolean; daysSinceLastRun: number; handicapWeight: number; silkUrl: string; formComment: string; sire: string; dam: string; last20Starts: string; trainerName: string; trainerLocation: string; Ev: number; FairPrice: number; }
export interface RaceCalculations { raceNumber: number; bankrollId: string; jurisdiction: string; type: string; meetingName: string; venueMnemonic: string; meetingDate: string; venue: string; meetingCode: string; scheduledType: string; runners: Runner[]; }

export class ApiService {
    private axiosInstance: AxiosInstance; constructor() { this.axiosInstance = axios.create({ baseURL: 'https://api.taby.in', headers: { 'Content-Type': 'application/json' }, withCredentials: true, }); this.axiosInstance.interceptors.request.use((config: any) => { const token = localStorage.getItem('accessToken'); if (token && config.headers) { config.headers.Authorization = `Bearer ${token}`; } return config; }, (error) => Promise.reject(error)); }
    async fetchMeetingDates(jurisdiction: string): Promise<MeetingDate[]> { try { const resp = await this.axiosInstance.get(`/api/meetings/dates?jurisdiction=${jurisdiction}`); return resp.data; } catch (error) { console.error("Error fetching meeting dates:", error); return []; } }
    async fetchMeetingsByType(date: string, raceType: string, jurisdiction: string): Promise<Meeting[]> { try { const resp = await this.axiosInstance.get(`/api/meetings/${date}/type/${raceType}`, { params: { jurisdiction, pageNumber: 1, pageSize: 50 }, }); return resp.data; } catch (error) { console.error("Error fetching meetings by type:", error); return []; } }
    async initializeBankroll(initialBankroll: number = 200): Promise<{ bankrollId: string }> { try { const resp = await this.axiosInstance.post('/bankroll/initialize', null, { params: { initialBankroll, profitThreshold: 150, stopLossThreshold: 50, }, }); return resp.data; } catch (error) { console.error("Error initializing bankroll:", error); throw error; } }
    async getCalculations(jurisdiction: string, meetingDate: string, venueMnemonic: string, raceType: string, raceNumber: number, bankrollId: string): Promise<RaceCalculations> { try { const resp = await this.axiosInstance.get(`/labs/punting/race-horsing/${jurisdiction}/${meetingDate}/${venueMnemonic}/${raceType}/race/${raceNumber}/get_selections/`); return resp.data; } catch (error) { console.error("Error fetching race calculations:", error); return { raceNumber: raceNumber, bankrollId: bankrollId, jurisdiction: jurisdiction, type: raceType, meetingName: venueMnemonic, venueMnemonic: venueMnemonic, meetingDate: meetingDate, venue: venueMnemonic, meetingCode: venueMnemonic, scheduledType: raceType, runners: [], }; } }
}

export const apiService = new ApiService();

export { };