// NestedGroupEditor.tsx

import React, { useCallback } from 'react';
import styled from 'styled-components';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import {
  ComparisonValueType,
  FilterCondition,
  FilterGroup,
  FilterOperator
} from './agents/operator_taby';

// A union type for a condition or nested group.
type ConditionOrGroup = FilterCondition | FilterGroup;

interface NestedGroupEditorProps {
  group: FilterGroup;
  onChange: (updatedGroup: FilterGroup) => void;
  customVariableNames: string[];
}

// Styled components
const Container = styled.div`
  margin-left: 20px;
  border-left: 2px solid #ccc;
  padding-left: 10px;
  font-family: Arial, sans-serif;
`;

const GroupTypeContainer = styled.div`
  margin-bottom: 10px;
`;

const ConditionContainer = styled.div`
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
`;

const Label = styled.label`
  font-weight: bold;
  margin-right: 5px;
`;

const Select = styled.select`
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #ccc;
`;

const Input = styled.input`
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #ccc;
`;

const Button = styled.button`
  margin-right: 5px;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

// Pure helper to decide whether a condition is a FilterGroup.
const isFilterGroup = (condition: FilterCondition | FilterGroup): condition is FilterGroup =>
  (condition as FilterGroup).groupType !== undefined;

const NestedGroupEditor: React.FC<NestedGroupEditorProps> = ({ group, onChange, customVariableNames }) => {
  // Purely add a new condition to the group.
  const addCondition = useCallback(() => {
    const newCondition: FilterCondition = {
      propertyName: '',
      operator: 'EQ',
      comparisonType: 'constant',
      comparisonValue: ''
    };
    onChange({ ...group, conditions: [...group.conditions, newCondition] });
  }, [group, onChange]);

  // Purely add a new nested group.
  const addGroup = useCallback(() => {
    const newGroup: FilterGroup = { groupType: 'AND', conditions: [] };
    onChange({ ...group, conditions: [...group.conditions, newGroup] });
  }, [group, onChange]);

  // Purely remove a condition or group at the given index.
  const removeConditionOrGroup = useCallback(
    (index: number) => {
      const newConditions = group.conditions.filter((_, i) => i !== index);
      onChange({ ...group, conditions: newConditions });
    },
    [group, onChange]
  );

  // Purely update a field on a FilterCondition.
  const updateCondition = useCallback(
    (index: number, field: keyof FilterCondition, value: any) => {
      const updatedConditions = group.conditions.map((cond, i) =>
        i === index && !isFilterGroup(cond) ? { ...cond, [field]: value } : cond
      );
      onChange({ ...group, conditions: updatedConditions });
    },
    [group, onChange]
  );

  // Purely update the group type.
  const updateGroupType = useCallback(
    (newType: 'AND' | 'OR') => {
      onChange({ ...group, groupType: newType });
    },
    [group, onChange]
  );

  return (
    <Container>
      <GroupTypeContainer>
        <Label>Group Type:</Label>
        <Select
          value={group.groupType}
          onChange={(e) => updateGroupType(e.target.value as 'AND' | 'OR')}
        >
          <option value="AND">AND</option>
          <option value="OR">OR</option>
        </Select>
      </GroupTypeContainer>
      {group.conditions.map((condOrGroup, index) => (
        <ConditionContainer key={index}>
          {isFilterGroup(condOrGroup) ? (
            // Recursively render nested group editor.
            <NestedGroupEditor
              group={condOrGroup}
              onChange={(updatedSubGroup) => {
                const newConditions = group.conditions.map((c, i) =>
                  i === index ? updatedSubGroup : c
                );
                onChange({ ...group, conditions: newConditions });
              }}
              customVariableNames={customVariableNames}
            />
          ) : (
            <div>
              <Button onClick={() => removeConditionOrGroup(index)}>Remove</Button>
              <div>
                <Label>Property:</Label>
                <Input
                  type="text"
                  value={condOrGroup.propertyName}
                  onChange={(e) => updateCondition(index, 'propertyName', e.target.value)}
                />
              </div>
              <div>
                <Label>Operator:</Label>
                <Select
                  value={condOrGroup.operator}
                  onChange={(e) =>
                    updateCondition(index, 'operator', e.target.value as FilterOperator)
                  }
                >
                  <option value="EQ">EQ</option>
                  <option value="NEQ">NEQ</option>
                  <option value="GT">GT</option>
                  <option value="GTE">GTE</option>
                  <option value="LT">LT</option>
                  <option value="LTE">LTE</option>
                  <option value="CONTAINS">CONTAINS</option>
                  <option value="NOT_CONTAINS">NOT_CONTAINS</option>
                  <option value="CONTAINS_ANY">CONTAINS_ANY</option>
                  <option value="NOT_CONTAINS_ANY">NOT_CONTAINS_ANY</option>
                  <option value="CONTAINS_ALL">CONTAINS_ALL</option>
                  <option value="WITHIN">WITHIN</option>
                  <option value="IS_TRUE">IS_TRUE</option>
                  <option value="IS_FALSE">IS_FALSE</option>
                </Select>
              </div>
              <div>
                <Label>Comparison Type:</Label>
                <Select
                  value={condOrGroup.comparisonType}
                  onChange={(e) =>
                    updateCondition(index, 'comparisonType', e.target.value as ComparisonValueType)
                  }
                >
                  <option value="constant">Constant</option>
                  <option value="property">Property</option>
                  <option value="variable">Variable</option>
                </Select>
              </div>
              <div>
                <Label>Value:</Label>
                {(() => {
                  // For multi-word operators, treat the value as a comma-separated list.
                  const multiOperators = ['CONTAINS_ANY', 'NOT_CONTAINS_ANY', 'CONTAINS_ALL'];
                  const isMulti = multiOperators.includes(condOrGroup.operator);
                  const currentValue =
                    isMulti && Array.isArray(condOrGroup.comparisonValue)
                      ? condOrGroup.comparisonValue.join(', ')
                      : condOrGroup.comparisonValue || '';
                  return (
                    <Input
                      type="text"
                      value={currentValue}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        const updatedValue = isMulti
                          ? newValue
                              .split(',')
                              .map((s) => s.trim())
                              .filter((s) => s.length > 0)
                          : newValue;
                        updateCondition(index, 'comparisonValue', updatedValue);
                      }}
                    />
                  );
                })()}
              </div>
            </div>
          )}
        </ConditionContainer>
      ))}
      <Button onClick={addCondition}>Add Condition</Button>
      <Button onClick={addGroup}>Add Group</Button>
    </Container>
  );
};

export default NestedGroupEditor;
