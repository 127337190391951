import React from 'react';
import styled from 'styled-components';

/**
 * ThemeToggleProps interface defines the props for the ThemeToggle component.
 * @property theme - Current theme ('light' or 'dark').
 * @property toggleTheme - Function to toggle the theme state.
 */
interface ThemeToggleProps {
  theme: 'light' | 'dark';
  toggleTheme: () => void;
}

/**
 * ThemeToggle Component
 * 
 * This component renders a styled toggle switch that allows the user to switch
 * between light and dark themes. The design is inspired by Windows 10 aesthetics,
 * featuring a sliding knob within a rounded switch and an accompanying label.
 * 
 * Usage:
 * <ThemeToggle theme={currentTheme} toggleTheme={handleToggleTheme} />
 */
const ThemeToggle: React.FC<ThemeToggleProps> = ({ theme, toggleTheme }) => {
  return (
    <ToggleContainer onClick={toggleTheme} title={theme === 'light' ? 'Switch to Dark Mode' : 'Switch to Light Mode'}>
      <ToggleSwitch theme={theme}>
        <ToggleKnob theme={theme} />
      </ToggleSwitch>
      <Label>{theme === 'light' ? 'Light Mode' : 'Dark Mode'}</Label>
    </ToggleContainer>
  );
};

export default ThemeToggle;

// Styled Components

// Container for the entire toggle switch and label
const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

// The toggle switch background
const ToggleSwitch = styled.div<{ theme: 'light' | 'dark' }>`
  width: 50px;
  height: 24px;
  border-radius: 12px;
  background-color: ${props => (props.theme === 'light' ? '#ccc' : '#555')};
  position: relative;
  transition: background-color 0.3s;
  margin-right: 8px;
`;

// The sliding knob inside the toggle switch
const ToggleKnob = styled.div<{ theme: 'light' | 'dark' }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 2px;
  left: ${props => (props.theme === 'light' ? '2px' : '28px')};
  transition: left 0.3s;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
`;

// Label for the toggle switch
const Label = styled.span`
  font-size: 14px;
  color: inherit;
`; 