/*************************************************************************************************
 * operator_taby.ts
 *
 * The core filter engine, action executor, workflow definitions, and AiOperatorWizard.
 * Also includes a `generateUUID` utility function.
/*************************************************************************************************/

import eventEmitter from "../../utils/EventEmitter";

/** Extended action types */
export type ExtendedActionType =
  | 'API_CALL'
  | 'EXECUTE_JS'
  | 'LOAD_COMPONENT'
  | 'PLACE_BET'
  | 'LOG_MESSAGE'
  | 'NOP'
  | 'SEND_WINDOW_MESSAGE'
  | 'OPEN_PROGRAM'
  | 'CALL_API'
  | 'CREATE_PROMPT'
  | 'CALL_AGENT'
  | 'SKIP_RUNNER'
  | 'SKIP_RACE'
  | 'SKIP_MEETING'
  | 'GENERATE_BET_STRING'
  | 'SEND_TO_OPERATOR';

/** Filter operators. Added new operators: CONTAINS_ANY, NOT_CONTAINS_ANY, and CONTAINS_ALL */
export type FilterOperator =
  | 'EQ'
  | 'NEQ'
  | 'GT'
  | 'GTE'
  | 'LT'
  | 'LTE'
  | 'CONTAINS'
  | 'NOT_CONTAINS'
  | 'CONTAINS_ANY'
  | 'NOT_CONTAINS_ANY'
  | 'CONTAINS_ALL'
  | 'WITHIN'
  | 'IS_TRUE'
  | 'IS_FALSE';

export type ComparisonValueType = 'constant' | 'property' | 'variable';

/** Data structures for filters */
export interface FilterCondition {
  propertyName: string;
  operator: FilterOperator;
  comparisonType: ComparisonValueType;
  comparisonValue?: any;
}

export interface RangeFilterCondition extends FilterCondition {
}

export interface FilterGroup {
  groupType: 'AND' | 'OR';
  conditions: (FilterCondition | FilterGroup)[];
}

export interface FilterDefinition {
  name: string;
  rootGroup: FilterGroup;
}

/** Data structures for the workflow engine */
export interface WorkflowAction {
  actionType: ExtendedActionType;
  config?: Record<string, any>;
}

export interface WorkflowRule {
  id: string;
  filter: FilterDefinition;
  onSuccessAction: WorkflowAction[];
  onFailAction?: WorkflowAction[];
}

export interface WorkflowDefinition {
  workflowName: string;
  rules: WorkflowRule[];
  customVariables?: CustomVariable[];
}

/** Custom variables */
export interface CustomVariable {
  name: string;
  expression: string;
}

/** Minimal meeting/race structure for the AiOperatorWizard */
export interface MeetingRecord {
  meetingId: string;
  races: RaceRecord[];
  [key: string]: any;
}
export interface RaceRecord {
  raceNumber: number;
  runners: any[];
  [key: string]: any;
}

/*************************************************************************************************
 * ActionExecutor
 *************************************************************************************************/
export class ActionExecutor {
  
  execute(action: WorkflowAction, context: any) {

    console.log('action', action);
    console.log('context', context);

    switch (action.actionType) {
      case 'API_CALL':
        console.log('[ActionExecutor] API_CALL:', action.config);
        break;
      case 'EXECUTE_JS':
        console.log('[ActionExecutor] EXECUTE_JS:', action.config);
        break;
      case 'LOAD_COMPONENT':
        console.log('[ActionExecutor] LOAD_COMPONENT:', action.config);
        break;
      case 'OPEN_PROGRAM':
        console.log('[ActionExecutor] OPEN_PROGRAM => config:', action.config);
        // Possibly do something like creating a new window in your "Windows" environment
        break;
      case 'CALL_API':
        console.log('[ActionExecutor] CALL_API:', action.config);
        break;
      case 'CREATE_PROMPT':
        console.log('[ActionExecutor] CREATE_PROMPT:', action.config);
        context._lastPrompt = action.config?.prompt || '';
        break;
      case 'CALL_AGENT':
        console.log('[ActionExecutor] CALL_AGENT => context:', context);
        break;
      case 'GENERATE_BET_STRING':
        console.log('[ActionExecutor] GENERATE_BET_STRING:', action.config);
        context._betString = `BET: ${action.config?.pattern || 'DEFAULT_PATTERN'}`;
        break;
      case 'SKIP_RUNNER':
        console.log('[ActionExecutor] SKIP_RUNNER for runner:', context.runnerName);
        context._skipRunner = true;
        break;
      case 'SKIP_RACE':
        console.log('[ActionExecutor] SKIP_RACE for race:', context.raceNumber);
        context._skipRace = true;
        break;
      case 'SKIP_MEETING':
        console.log('[ActionExecutor] SKIP_MEETING for venue:', context.venueMnemonic);
        context._skipMeeting = true;
        break;
      case 'PLACE_BET':
        console.log('[ActionExecutor] PLACE_BET => config:', action.config);
        break;
      case 'LOG_MESSAGE':
        console.log('[ActionExecutor] LOG_MESSAGE:', action.config?.message, 'Context:', context);
        break;
      case 'SEND_WINDOW_MESSAGE':
        console.log('[ActionExecutor] SEND_WINDOW_MESSAGE => config:', action.config, 'Context:', context);
        break;
      case 'SEND_TO_OPERATOR':
        console.log('[ActionExecutor] SEND_TO_OPERATOR => sending data to operator:\n', context);
        break;
      case 'NOP':
      default:
        // no-op
        break;
    }
  }  
}

/*************************************************************************************************
 * FilterEvaluator
 *************************************************************************************************/
function isFilterGroup(obj: any): obj is FilterGroup {
  return obj && typeof obj === 'object' && 'groupType' in obj && Array.isArray(obj.conditions);
}

export class FilterEvaluator {
  evaluateFilter(def: FilterDefinition, dataObj: any, customVars: Record<string, any> = {}): boolean {
    return this.evaluateGroup(def.rootGroup, dataObj, customVars);
  }

  evaluateGroup(group: FilterGroup, dataObj: any, customVars: Record<string, any>): boolean {
    const results: boolean[] = [];
    for (const conditionOrGroup of group.conditions) {
      if (isFilterGroup(conditionOrGroup)) {
        results.push(this.evaluateGroup(conditionOrGroup, dataObj, customVars));
      } else {
        results.push(this.evaluateCondition(conditionOrGroup, dataObj, customVars));
      }
    }
    return group.groupType === 'AND' ? results.every(Boolean) : results.some(Boolean);
  }

  evaluateCondition(cond: FilterCondition, dataObj: any, customVars: Record<string, any>): boolean {
    try {
      const leftVal = Object.prototype.hasOwnProperty.call(dataObj, cond.propertyName)
        ? dataObj[cond.propertyName]
        : undefined;
      let rightVal;

      if (cond.comparisonType === 'constant') {
        rightVal = cond.comparisonValue;
      } else if (cond.comparisonType === 'property') {
        const property2 = cond.comparisonValue as string;
        rightVal = Object.prototype.hasOwnProperty.call(dataObj, property2)
          ? dataObj[property2]
          : undefined;
        if (rightVal === undefined) {
          console.warn(`Comparison property '${property2}' not found. Condition fails.`);
          return false;
        }
      } else if (cond.comparisonType === 'variable') {
        const varName = cond.comparisonValue as string;
        rightVal = customVars[varName];
        if (rightVal === undefined) {
          console.warn(`Custom variable '${varName}' not found. Condition fails.`);
          return false;
        }
      } else {
        console.warn(`Unknown comparison type: ${cond.comparisonType}. Condition fails.`);
        return false;
      }

      if (leftVal === undefined) {
        console.warn(`Property '${cond.propertyName}' not found. Condition fails.`);
        return false;
      }

      let result: boolean;
      switch (cond.operator) {
        case 'EQ':
          result = String(leftVal) === String(rightVal);
          break;
        case 'NEQ':
          result = leftVal !== rightVal;
          break;
        case 'GT':
          result = leftVal > rightVal;
          break;
        case 'GTE':
          result = leftVal >= rightVal;
          break;
        case 'LT':
          result = leftVal < rightVal;
          break;
        case 'LTE':
          result = leftVal <= rightVal;
          break;
        case 'CONTAINS':
          result = (
            typeof leftVal === 'string' &&
            typeof rightVal === 'string' &&
            leftVal.toLowerCase().includes(rightVal.toLowerCase())
          );
          break;
        case 'NOT_CONTAINS':
          result = (
            typeof leftVal === 'string' &&
            typeof rightVal === 'string' &&
            !leftVal.toLowerCase().includes(rightVal.toLowerCase())
          );
          break;
        case 'CONTAINS_ANY':
          // Expect rightVal to be an array of words.
          if (typeof leftVal === 'string' && Array.isArray(rightVal)) {
            result = rightVal.some((word: string) =>
              leftVal.toLowerCase().includes(word.toLowerCase())
            );
          } else {
            result = false;
          }
          break;
        case 'NOT_CONTAINS_ANY':
          if (typeof leftVal === 'string' && Array.isArray(rightVal)) {
            result = !rightVal.some((word: string) =>
              leftVal.toLowerCase().includes(word.toLowerCase())
            );
          } else {
            result = false;
          }
          break;
        case 'CONTAINS_ALL':
          if (typeof leftVal === 'string' && Array.isArray(rightVal)) {
            result = rightVal.every((word: string) =>
              leftVal.toLowerCase().includes(word.toLowerCase())
            );
          } else {
            result = false;
          }
          break;
        case 'WITHIN':
          // expects array [min, max]
          if (Array.isArray(rightVal) && rightVal.length === 2) {
            result = leftVal >= rightVal[0] && leftVal <= rightVal[1];
          } else {
            result = false;
          }
          break;
        case 'IS_TRUE':
          result = !!leftVal === true;
          break;
        case 'IS_FALSE':
          result = !!leftVal === false;
          break;
        default:
          console.warn(`Unknown operator: ${cond.operator}`);
          return false;
      }

      console.log(`Evaluating condition: ${cond.operator} with leftVal: ${leftVal}, rightVal: ${rightVal}, result: ${result}`);
      return result;
    } catch (error) {
      console.error('Error in evaluateCondition:', error, cond, dataObj);
      return false;
    }
  }
}

/*************************************************************************************************
 * WorkflowEngine
 *************************************************************************************************/
export class WorkflowEngine {
  constructor(private filterEvaluator: FilterEvaluator) {}

  run(def: WorkflowDefinition, dataObj: any, customVariables: CustomVariable[] = []): boolean {
    if (!def || !def.rules) {
      console.warn('No workflow/rules defined. Passing all by default.');
      return true;
    }

    const evaluatedVars: Record<string, any> = {};
    customVariables.forEach((v) => {
      try {
        const expr = v.expression.replace(/runner\./g, 'dataObj.');
        evaluatedVars[v.name] = eval(expr);
      } catch (e) {
        console.error(`Error evaluating custom variable '${v.name}':`, e);
        evaluatedVars[v.name] = undefined;
      }
    });

    if (!dataObj._rulePassStates) {
      dataObj._rulePassStates = {};
    }

    let allRulesPassed = true;
    def.rules.forEach((rule) => {
      const pass = this.filterEvaluator.evaluateFilter(rule.filter, dataObj, evaluatedVars);
      dataObj._rulePassStates[rule.id] = pass;

      if (!pass) {
        allRulesPassed = false;
        rule.onFailAction?.forEach((a) => {
          const executor = new ActionExecutor();
          executor.execute(a, dataObj);
        });
      } else {
        rule.onSuccessAction.forEach((a) => {
          const executor = new ActionExecutor();
          executor.execute(a, dataObj);
        });
      }
    });

    return allRulesPassed;
  }
}

/*************************************************************************************************
 * AiOperatorWizard
 *************************************************************************************************/
export interface AiOperatorWizardEnvironment {
  fetchMeetings?: (date: string, jurisdiction: string, raceType: string) => Promise<MeetingRecord[]>;
}

export interface AiOperatorWizardConfig {
  environment?: AiOperatorWizardEnvironment;
  workflowDefinition: WorkflowDefinition;
  date?: string;
  jurisdiction?: string;
  raceType?: string;
  meetingRecords?: MeetingRecord[];
}

export class AiOperatorWizard {
  private engine: WorkflowEngine;
  private processedData: MeetingRecord[] = [];

  constructor(private config: AiOperatorWizardConfig) {
    this.engine = new WorkflowEngine(new FilterEvaluator());
  }

  async fetchDataIfNeeded(): Promise<void> {
    if (this.config.meetingRecords) return;
    if (!this.config.environment?.fetchMeetings) {
      console.warn('No fetchMeetings or meetingRecords provided.');
      return;
    }
    if (!this.config.date || !this.config.jurisdiction || !this.config.raceType) {
      console.warn('Missing date/jurisdiction/raceType. Skipping fetch.');
      return;
    }
    const { date, jurisdiction, raceType } = this.config;
    try {
      const data = await this.config.environment.fetchMeetings(date, jurisdiction, raceType);
      this.config.meetingRecords = data;
    } catch (err) {
      console.error('Error in fetchDataIfNeeded:', err);
    }
  }

  async processAllMeetings(): Promise<void> {
    if (!this.config.workflowDefinition) {
      console.error('No workflowDefinition. Aborting.');
      return;
    }
    await this.fetchDataIfNeeded();
    if (!this.config.meetingRecords || this.config.meetingRecords.length === 0) {
      console.warn('No meetingRecords to process.');
      return;
    }
    this.processedData = [];

    for (const meeting of this.config.meetingRecords) {
      if (meeting._skipMeeting) {
        console.log('Skipping entire meeting:', meeting.meetingId);
        continue;
      }
      for (const race of meeting.races) {
        if (race._skipRace) {
          console.log('Skipping entire race:', race.raceNumber, 'in meeting:', meeting.meetingId);
          continue;
        }
        for (const runner of race.runners) {
          if (runner._skipRunner) continue;
          const contextObj = { ...meeting, ...race, ...runner };
          contextObj.meetingId = meeting.meetingId;
          contextObj.raceNumber = race.raceNumber;

          const pass = this.engine.run(
            this.config.workflowDefinition,
            contextObj,
            this.config.workflowDefinition.customVariables || []
          );
          runner.excluded = !pass;
          runner._skipRunner = contextObj._skipRunner;
          runner._betString = contextObj._betString || '';
          if (contextObj._skipMeeting) {
            meeting._skipMeeting = true;
            break;
          }
          if (contextObj._skipRace) {
            race._skipRace = true;
            break;
          }
        }
      }
    }
    this.processedData = this.config.meetingRecords.slice();
  }

  processDataRecords(records: any[]): any[] {
    if (!this.config.workflowDefinition) {
      console.error('No workflowDefinition. Aborting.');
      return records;
    }
    const output: any[] = [];
    for (const record of records) {
      if (record._skipRecord) {
        output.push(record);
        continue;
      }
      const pass = this.engine.run(
        this.config.workflowDefinition,
        record,
        this.config.workflowDefinition.customVariables || []
      );
      record.excluded = !pass;
      output.push(record);
    }
    return output;
  }

  getResults(): MeetingRecord[] {
    return this.processedData;
  }
}

/*************************************************************************************************
 * generateUUID (utility)
 *************************************************************************************************/
export function generateUUID(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
