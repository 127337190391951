import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App'; // Assuming App.tsx is in the same directory

// Create a root.
const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);

// Render the App component within the UserContextProvider and React.StrictMode.
root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
); 