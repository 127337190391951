/*************************************************************************************************
 * gemini_chat.tsx
 *
 * A placeholder for a Gemini-based LLM chat UI. Adjust as needed.
 *************************************************************************************************/

import React from 'react';
import NextToGoRacesFetcher from '../Bankroll/NextToGoRacesFetcher';

export function GeminiChat() {
  return (
    <div style={{ padding: 16 }}>
      <h3 style={{ marginTop: 0 }}>Gemini Chat</h3>
    </div>
  );
}
