/*************************************************************************************************
 * TabyProvider.tsx
 * 
 * Self-aware AI management for bankrolls, race meetings, and predictions.
 *************************************************************************************************/

import React, { createContext, useState, useContext, useEffect } from "react";
import { gpt } from "../services/gpt";

interface TabyContextType {
  determineBestAction: (message: string) => Promise<string>;
}

const TabyContext = createContext<TabyContextType | undefined>(undefined);
export const TabyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

  const determineBestAction = async (message: string): Promise<string> => {
    let response = await gpt.queryGPT(message, {
    });

    return response;
  };

  return (
    <TabyContext.Provider
      value={{      
        determineBestAction
      }}
    >
      {children}
    </TabyContext.Provider>
  );
};

export const useTaby = () => {
  const context = useContext(TabyContext);
  if (!context) throw new Error("useTaby must be used within a TabyProvider");
  return context;
};
