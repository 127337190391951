/*************************************************************************************************
 * operator_bridge.tsx
 *
 * Demonstrates how an agent or AI can manipulate TabyWizardNoClassic via a ref
 * (the same wizard a user interacts with in the UI).
 *
 * This version loads the current bankroll from local storage and passes its unique
 * identifier to the Taby wizard. It also supports full bridge compatibility with legacy
 * methods (e.g., getRootGroup/setRootGroup) and has UI enhancements (spinners, status).
 *************************************************************************************************/

import React, { useRef, useState, useEffect } from 'react';
import { generateUUID, FilterCondition, FilterGroup } from './operator_taby';
import { Taby, TabyWizardRef } from '../Taby/Taby';
import { Bankroll } from '../../interfaces/Bankroll';

export function OperatorBridge() {
  const wizardRef = useRef<TabyWizardRef>(null);
  const [processing, setProcessing] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [bankrollId, setBankrollId] = useState<string>(generateUUID());
  const [statusMessage, setStatusMessage] = useState<string>('');

  async function newAgentDriveWizard() {
    if (!wizardRef.current) {
      console.warn('Wizard ref not available.');
      return;
    }

    // If already processing then cancel the current operation.
    if (processing) {
      wizardRef.current.cancelProcessing();
      setCancelled(true);
      setProcessing(false);
      setStatusMessage('Processing cancelled by agent.');
      return;
    }

    setCancelled(false);
    setProcessing(true);
    setStatusMessage('Agent is preparing wizard...');

    try {
      // Set the wizard date to today (or adjust as needed)
      const today = new Date().toISOString().split('T')[0];
      wizardRef.current.setDate(today);

      // Simulate delay (e.g., waiting for data to load)
      await new Promise(resolve => setTimeout(resolve, 3000));
      if (cancelled) return;

      // Select all meetings in the wizard
      wizardRef.current.selectAllMeetings();
      const newGroup: FilterGroup = {
        groupType: 'AND',
        conditions: [
          // Must be a value bet
          {
            propertyName: 'isValueBet',
            operator: 'IS_TRUE',
            comparisonType: 'constant',
            comparisonValue: true,
          } as FilterCondition,
          // Must have a winning bet result
          {
            propertyName: 'betResultType',
            operator: 'EQ',
            comparisonType: 'constant',
            comparisonValue: 1,
          } as FilterCondition,
          // Current odds should be between 1.5 and 3.5
          {
            propertyName: 'currentOdds',
            operator: 'WITHIN',
            comparisonType: 'constant',
            comparisonValue: [1.5, 3.5],
          } as FilterCondition,
          // Fair odds should be between 4.5 and 7.0
          {
            propertyName: 'fairOdds',
            operator: 'WITHIN',
            comparisonType: 'constant',
            comparisonValue: [4.5, 7.0],
          } as FilterCondition,
          // The overlay (fairOdds - currentOdds) should be at least 1.5
          {
            propertyName: 'overlay',
            operator: 'GTE',
            comparisonType: 'constant',
            comparisonValue: 1.5,
          } as FilterCondition,
          // The probability score should be between 0.10 and 0.30
          {
            propertyName: 'probabilityScore',
            operator: 'WITHIN',
            comparisonType: 'constant',
            comparisonValue: [0.10, 0.30],
          } as FilterCondition,
          // The stability score should be between 7 and 12
          {
            propertyName: 'stabilityScore',
            operator: 'WITHIN',
            comparisonType: 'constant',
            comparisonValue: [7, 12],
          } as FilterCondition,
          // The average pace should be between 15 and 18 seconds
          {
            propertyName: 'averagePace',
            operator: 'WITHIN',
            comparisonType: 'constant',
            comparisonValue: [15, 18],
          } as FilterCondition,
          // Must have good pace (isPaceGood = true)
          {
            propertyName: 'isPaceGood',
            operator: 'IS_TRUE',
            comparisonType: 'constant',
            comparisonValue: true,
          } as FilterCondition,
        ],
      };

      // Use legacy method to set the root group for filtering
      wizardRef.current.setRootGroup(newGroup);
      setStatusMessage('Filter group set. Running process...');

      // Run the full processing workflow
      await wizardRef.current.runProcessAll();
      if (cancelled) return;

      // Retrieve results from the wizard
      const results = wizardRef.current.getResults();
      console.log('[OperatorBridge] Agent retrieved results:', results);
      setStatusMessage(`Processing complete. ${results.length} races processed.`);

      // Dispatch a custom event with the results (for any external listeners)
      const event = new CustomEvent('wizardProcessComplete', { detail: results });
      window.dispatchEvent(event);
    } catch (error) {
      console.error('Error in newAgentDriveWizard:', error);
      setStatusMessage('An error occurred during processing.');
    } finally {
      setProcessing(false);
    }
  }

  return (
    <div style={{ padding: 20, textAlign: 'center' }}>
      <h3>
        Hello agents, welcome to your AI-enhanced horse racing prediction service!
      </h3>
      <p>
        This demonstrates how an AI or automated system can manipulate TabyWizardNoClassic
        via a React ref.
      </p>
      <button
        onClick={newAgentDriveWizard}
        style={{
          width: 150,
          height: 150,
          borderRadius: '50%',
          backgroundColor: processing ? '#dc3545' : '#007BFF',
          color: '#fff',
          fontSize: '1.2rem',
          border: 'none',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '20px auto',
          transition: 'background-color 0.3s',
        }}
      >
        {processing ? 'Cancel' : 'Prepare'}
      </button>
      {processing && (
        <div style={{ margin: '10px auto', fontStyle: 'italic' }}>
          <span role="img" aria-label="spinner">⏳</span> Processing...
        </div>
      )}
      {statusMessage && (
        <div style={{ marginTop: 10, color: '#555' }}>
          {statusMessage}
        </div>
      )}

      {/* Pass the current bankrollId to the wizard */}
      <Taby
        ref={wizardRef}
        jurisdiction="VIC"
        raceType="R"
        bankrollId={bankrollId}
      />
    </div>
  );
}
